
import '../config';
import Header from './header';
import Footer from './footer';
import HeaderOffer from './HeaderOffer';
import CompanyInfo from './company_info';
import RelatedProducts from './RelatedProducts';
import OrderShipping from './order_shipping';

const ContactUs = ({onAddItem, items, totalItems}) => {
    return (
        <div >
            
            <HeaderOffer />
            
            <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
            
            <CompanyInfo is_home={false} />
            
            {global.config.site.show_related_products_pages === '1' && (
                    <RelatedProducts />
            )}

            {global.config.site.show_order_shipping_pages === '1' && (
                    <OrderShipping />
            )}

            <Footer />
        </div>
    );
};

export default ContactUs;