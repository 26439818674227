
import Container from 'react-bootstrap/Container';
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import CartMenu from '../../components/cart_menu';
import '../../config';
import { useEffect } from 'react';

export const Header = ({onAddItem, items, totalItems}) => {
    
    
    const location = useLocation();
    useEffect(() => {
        
    }, [location]);

    function GetLogo(){
        if (global.config.site.nav_logo){
            
            return <Image className={global.config.site.header_logo_spacing} height={global.config.site.header_logo_height} src={global.config.site.nav_logo} />;
        } else {
            if (global.config.site.nav_title){
                return <Navbar.Brand href="/"><h3>{global.config.site.nav_title}</h3></Navbar.Brand>;
            }
        }
    }

    const navLinkStyle = {
        fontSize: global.config.site.nav_link_text_font_size,
        fontWeight: global.config.site.nav_link_text_font_weight,
        fontFamily: global.config.site.nav_link_text_font_family,
    };

    const containerStyle = {
        backgroundColor: global.config.site.header_background_color
    }

    return (
        
        <Container style={containerStyle} fluid>
            <Stack className="justify-content-center pt-5" direction="horizontal" gap={3}>
                <GetLogo></GetLogo>
            </Stack>
            <Navbar className="justify-content-center" expand="md">
                
                
                <CartMenu className='d-block d-md-none pe-3' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className='float-right'>
                    
                </Navbar.Toggle>
                
                <Navbar.Collapse className="justify-content-center" id="basic-navbar-nav">
                
                    <Nav activeKey={location.pathname} className="justify-content-center">
                    
                        <Nav.Link className='me-2' style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                        
                        <Nav.Link className='me-2' style={navLinkStyle} href="/shop">{'Shop'.toUpperCase()}</Nav.Link>
                        <Nav.Link className='me-2' style={navLinkStyle} href="/cart">{'Cart'.toUpperCase()}</Nav.Link>
                        <Nav.Link className='me-2' style={navLinkStyle} href="/contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                        <CartMenu className='d-none d-md-block' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                        
                    </Nav>
                
                </Navbar.Collapse>
                
            </Navbar>
        </Container>
        
        
    );
};
