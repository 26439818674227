
import '../config';
import Header from './header';
import Discover from './discover';
import BestSeller from './best_sellers';
import OrderShipping from './order_shipping';
import CompanyInfo from './company_info';
import Footer from './footer';
import HeaderOffer from './HeaderOffer';
import Hero from './Hero';
import React from "react";

const Home = ({onAddItem, items, totalItems}) => {
    
    return (
        <div >
            
            <HeaderOffer />
            
            <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
            
            {global.config.site.hero_section_use === '1' && (
                <Hero />
            )}
            {global.config.site.discover_use === '1' && (
                <Discover />
            )}
            {global.config.site.best_seller_use === '1' && (
                <BestSeller />
            )}
            
            

            {global.config.site.contact_us_show_home === '1' && (
                <CompanyInfo is_home={true} />
            )}

            {global.config.site.order_shipping_show_home === '1' && (
                <OrderShipping />
            )}
            
            <Footer />
        </div>
    );
};

export default Home;