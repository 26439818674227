
import { Row, Col, Container} from 'react-bootstrap';
import Header from './header';
import '../config';
import Footer from "./footer";
import HeaderOffer from './HeaderOffer';

const ShippingPolicy = ({ items, onRemoveItem, onAddItem, onUpdateQtyItem, subTotal, setCartItem, totalItems }) => {
  const h1Style = {
    padding: global.config.site.page_heading_padding,
}   

const innerContainerStyle = {
  padding: global.config.site.terms_inner_container_padding,
  backgroundColor: global.config.site.terms_inner_container_background_color,
  border: global.config.site.terms_inner_container_border,
  borderRadius: global.config.site.terms_inner_container_border_radius,
}


  const rowHeaderStyle = {
    backgroundColor: global.config.site.pages_header_background_color,
    backgroundImage: global.config.site.pages_header_background_image,
    backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
    padding: global.config.site.pages_header_padding,
    color: global.config.site.pages_header_font_color,
    fontSize: global.config.site.pages_header_font_size,
    fontWeight: global.config.site.pages_header_font_weight,
    backgroundSize: global.config.site.pages_header_background_size,
    backgroundPosition: global.config.site.pages_header_background_position,
    backgroundRepeat: global.config.site.pages_header_background_repeat,
    position: global.config.site.pages_header_position,
    height: global.config.site.pages_header_height,
    margin: global.config.site.pages_header_margin,
  }

  const rowStyle = {
        backgroundColor: global.config.site.page_container_background_color,
        backgroundImage: global.config.site.page_container_background_image,
        padding: global.config.site.page_container_padding,
    }

  return (
    <>
        <HeaderOffer />
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Row style={rowStyle} className='g-0'>

                <div style={rowHeaderStyle}>
                    <center><h1 style={h1Style}>Shipping Policy</h1></center>
                </div> 

                <Container className={global.config.site.inner_container_size}>
            
            <Row style={innerContainerStyle}  className='m-3 mb-5'>
            
                <Row className="mt-3 g-0">
                    <Col>
                    <p>We want to ensure your shopping experience is smooth and hassle-free. Please read the following Shipping and Returns Policy carefully to understand how we handle shipping, returns, and exchanges.</p>
                    <p><h4>Shipping Information:</h4></p>
                    <p>
                    1. Processing Time: Orders are typically processed within 1-3 business days (excluding holidays and weekends) from the time of purchase. During peak seasons or promotions, processing times may be slightly longer.
                    <br></br>
                    2. Shipping Methods: We offer various shipping options at checkout, including standard and expedited shipping. The estimated delivery times and costs associated with each method will be displayed during the checkout process.
                    <br></br>
                    3. Shipping Destinations: We currently ship to addresses within the United States.
                    <br></br>
                    4. Shipping Confirmation: Once your order is shipped, you will receive a confirmation email containing your tracking number and a link to track your package’s progress.
                    <br></br>
                    5. Undeliverable Packages: In the event that a package is deemed undeliverable due to incorrect address information provided by the customer or refusal to accept delivery, we may contact you to arrange for reshipment. Additional shipping charges may apply.
                    <br></br>
                    6. Shipping From:  We use a third party fulfillment service to fulfill all of our orders. Fulfillment Center information
                    </p>
                    <p>Fulfillment Center <br></br>{global.config.terms.return_address}<br></br>{global.config.terms.return_address2}</p>
                    </Col>
                </Row>
                
                <Row className="mt-3 g-0">
                
                </Row>
                
                

              
            </Row>

            
        </Container>
        </Row>
        <Footer />
        </>
  );
};

export default ShippingPolicy;
