
import { Container, Button, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../config';

export const Hero = () => {
    
    const containerStyle = {
        backgroundColor: global.config.site.hero_background_color,
        backgroundBlendMode: 'overlay',
        backgroundImage: global.config.site.hero_background_image,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: global.config.site.hero_container_padding,
    }

    const buttonStyle = {
        fontSize: '20px',
    };

    const h1Style = {
        fontSize: global.config.site.hero_title_font_size,
        fontWeight: global.config.site.hero_title_font_weight,
        color: global.config.site.hero_title_font_color,
    }

    const pStyle = {
        fontSize: global.config.site.hero_description_font_size,
        fontWeight: global.config.site.hero_description_font_weight,
        color: global.config.site.hero_description_font_color,
    }

    return (
        <Row className='g-0'>
        <Container style={containerStyle} className="justify-content-center g-0" fluid>
            <Container className={global.config.site.inner_container_size}>
            <div className='px-4 py-5 my-5 text-center'>
                <h1 style={h1Style}>{global.config.site.hero_title_text}</h1>
                <div className='col-lg-8 mx-auto'>
                    <p style={pStyle}>{global.config.site.hero_description_text}</p>
                    <div className='justify-content-center d-sm-flex'>
                        <Link to="/shop">
                            <Button className="pr-5 pl-5" style={buttonStyle} variant="outline-primary">{global.config.site.hero_button_text}</Button>{' '}
                        </Link>
                    </div>
                </div>
            </div>
            </Container>
        </Container>
        </Row>
    );
};
