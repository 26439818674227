import {ContactUs as ContactUs1} from '../contact_us/1/ContactUs';
import {ContactUs as ContactUs2} from '../contact_us/2/ContactUs';
import {ContactUs as ContactUs3} from '../contact_us/3/ContactUs';
import '../config';

const CompanyInfo = ({is_home}) => {
    
    return (
        <>
            {global.config.site.contact_us_type === '1' && (
                <ContactUs1 is_home={is_home} />
            )}
            {global.config.site.contact_us_type === '2' && (
                <ContactUs2 is_home={is_home} />
            )}
            {global.config.site.contact_us_type === '3' && (
                <ContactUs3 is_home={is_home} />
            )}
        </>
    );
};

export default CompanyInfo;