import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect, useCallback } from "react";
import Posts from './components/shop';
import Post from './components/product';
import Home from './components/home';
import Cart from './components/Cart';
import './App.css';
import './config';
import Checkout from './components/Checkout';
import ThankYou from './components/ThankYou';
import TermsConditions from './components/TermsConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ShippingPolicy from './components/ShippingPolicy';
import ContactUs from './components/ContactUs';

function dynamicSort(property) {
  var sortOrder = 1;
  if(property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
  }
  return function (a,b) {
      /* next line works with strings and numbers, 
       * and you may want to customize it to your needs
       */
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
  }
}

function App() {

  const [items, setCartItem] = useState([]);
  const [subTotal, setSubTotal] = useState(0.00);
  const [totalItems, setTotalItems] = useState(0);
  const [siteConfig, setSiteConfig] = useState({});

  const onRemoveItem = (idToRemove) => {
    const updatedCart = items.filter(item => item.id !== idToRemove);
    
    
    setCartItem(updatedCart);
    

    let stringlist = JSON.stringify(updatedCart)
    
    window.localStorage.setItem("items", stringlist);

    let sum = updatedCart.reduce(function(prev, current) {
      return prev + +current.sub_total
    }, 0);
    setSubTotal(sum);

    let sumItems = updatedCart.reduce(function(prev, current) {
      return prev + +current.qty
    }, 0);
    setTotalItems(sumItems);
    
  };

  const onAddItem = useCallback((item_sent, items, original= false) => {
    const item = item_sent;
    const findInCart = items.find(item => item.id === item_sent.id);
    
    const updatedWithoutCart = items.filter(item => item.id !== item_sent.id);
    if (findInCart){
      if (original){
        updatedWithoutCart.push(findInCart);
        updatedWithoutCart.sort(dynamicSort('id'));
      } else {
        findInCart.qty = parseInt(findInCart.qty) + parseInt(item_sent.qty);
        findInCart.sub_total = parseFloat(findInCart.qty) * parseFloat(findInCart.price)
        updatedWithoutCart.push(findInCart);
        updatedWithoutCart.sort(dynamicSort('id'));
        
        
      }

      setCartItem(updatedWithoutCart);

    } else {
      
      
      setCartItem((prev) => [...prev, item]);
      
    }
    
    // setCartItem((prev) => [...prev, item]);
  }, []);


  const onUpdateQtyItem = (item_id, qty) => {
    
    const findInCart = items.find(item => item.id === item_id);
    
    const updatedWithoutCart = items.filter(item => item.id !== item_id);
    if (findInCart){
      findInCart.qty = parseInt(qty);
      findInCart.sub_total = parseFloat(qty) * parseFloat(findInCart.price);
      updatedWithoutCart.push(findInCart);

      updatedWithoutCart.sort(dynamicSort('id'));
      setCartItem(updatedWithoutCart);
    }
    
    let sum = items.reduce(function(prev, current) {
      return prev + +current.sub_total
    }, 0);
    setSubTotal(sum);

    let sumItems = items.reduce(function(prev, current) {
      return prev + +current.qty
    }, 0);
    setTotalItems(sumItems);
    // setCartItem((prev) => [...prev, item]);
  };

  // if blank, we need to pull it from the session
  useEffect(() => {
    
    // document.title = global.config.site.site_title; 
    document.querySelector("meta[name='description']").setAttribute('content', global.config.site.site_description); 

    var i;
    let retString = window.localStorage.getItem("items");
    if (retString){
      
      let retArray = JSON.parse(retString);
      if (retArray.length > 0 ){
        retArray.sort(dynamicSort('id'));
        for(i=0; i < retArray.length; i ++){
          
          onAddItem(retArray[i],retArray, true);
          
        }
      }
      
    }
    
  }, [onAddItem]);

  

  async function fetchData() {
    let retString = window.localStorage.getItem("site");

    let retStringArry = JSON.parse(retString);
   
    
    if (retStringArry){
      
    if (retStringArry.hasOwnProperty("expire") ){
      
      let date_expire = Date.parse(retStringArry.expire);
      let date_now = Date.parse(Date())
      
      if (date_expire < date_now){
          window.localStorage.removeItem("site");
          retString = false;
      }
    }
  }
    if (global.config.api.site_use_session_config === "1") {
      if (retString === null){
        retString = false;
      }
    } else {
        retString = false;
    }
    
    if (global.config.api.site_use_config_file === "1" && !retString){
        
      let resultsJson = {};
        try {
          const resp = await fetch(`${global.config.api.site_config_file}`);
        
          resultsJson = await resp.json();
        } catch {
          
        }

        console.log(resultsJson);
        if (Object.keys(resultsJson).length > 0){
          
            console.log('adding');
            global.config.site = resultsJson;
          
        } else {
          console.log('skipping');
        }

        window.localStorage.setItem("site", JSON.stringify({expire: new Date(Date.now() + (global.config.api.site_session_config_expiration * 60 * 1000)), data: global.config.site}));
        setSiteConfig(global.config.site);
    } else {
      
        if (!retString){
          setSiteConfig(global.config.site);
          window.localStorage.setItem("site", JSON.stringify({expire: new Date(Date.now() + (global.config.api.site_session_config_expiration * 60 * 1000)), data: global.config.site}));
        } else {
          global.config.site = JSON.parse(retString).data;
          setSiteConfig(global.config.site);
        }
        
    }
}



  //update session when item is added to the list
  useEffect(() => {
    
    fetchData();
    
    let stringlist = JSON.stringify(items)
    
    if (items.length > 0 ){
      let sum = items.reduce(function(prev, current) {
        return prev + +current.sub_total
      }, 0);
      setSubTotal(sum);

      let sumItems = items.reduce(function(prev, current) {
        return prev + +current.qty
      }, 0);
      setTotalItems(sumItems);

      window.localStorage.setItem("items", stringlist);
    }
  }, [items]);

  return (
    <>
    
    <Router>
      <style>
        {`
          .btn-outline-primary {
            color: ${siteConfig.button_text_color} !important;
            background-color: ${global.config.site.button_background_color};
            border: ${global.config.site.button_border};
            border-radius: ${global.config.site.button_border_radius};
          }
          .btn-outline-primary:hover {
            color: ${global.config.site.button_text_hover_color} !important;
            background-color: ${global.config.site.button_hover_background_color};
            border: ${global.config.site.button_hover_border};
            border-radius: ${global.config.site.button_hover_border_radius};
          }

          .form-control{
              background-color:  ${global.config.site.input_background_color}!important;
          }
          .form-select{
              background-color:  ${global.config.site.input_background_color}!important;
          }

          body, html {
            font-family: ${global.config.site.font_family} !important;
            font-size: ${global.config.site.font_size} !important;
            background-color: ${global.config.site.background_color} !important;
            background-image: ${global.config.site.background_image} !important;
            background-repeat: ${global.config.site.background_repeat} !important;
          }
          
        `}
      </style>
    <Routes>
      <Route path="/" element={<Home onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/shop" element={<Posts onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/thank-you" element={<ThankYou onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/contact-us" element={<ContactUs onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
      <Route path="/cart" element={<Cart onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} />} />
      <Route path="/checkout" element={<Checkout onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/terms-and-conditions" element={<TermsConditions onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/shipping-policy" element={<ShippingPolicy onRemoveItem={onRemoveItem} items={items} totalItems={totalItems} onAddItem={onAddItem} onUpdateQtyItem={onUpdateQtyItem} subTotal={subTotal} setCartItem={setCartItem} />} />
      <Route path="/product/:id" element={<Post onAddItem={onAddItem} items={items} totalItems={totalItems} />} />
    </Routes>
  </Router>
    
  </>
  );
        
}

export default App;