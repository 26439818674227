
import { Discover as Discover1 } from '../discovers/1/discover';
import { Discover as Discover2 } from '../discovers/2/discover';
import '../config';

const Discover = () => {
    
    return (
        <>
            { global.config.site.discover_use_type === '1' && (
                <Discover1></Discover1>
            )}
            { global.config.site.discover_use_type === '2' && (
                <Discover2></Discover2>
            )}
            
            { global.config.site.discover_use_type === undefined && (
                <Discover1></Discover1>
            )}
        </>
        
    );
};

export default Discover;