import React from 'react';
import { Link } from "react-router-dom";
import { Col, Image} from 'react-bootstrap';
import '../config';

const ShopItem = ({onAddItem, items, currentItems}) => {

    const colStyle = {
        border: global.config.site.best_seller_border,
        height: '100%',
        padding: global.config.site.best_seller_padding,
        backgroundColor: global.config.site.best_seller_title_background_color,
        borderRadius: global.config.site.best_seller_border_radius
    };

    const titleStyle = {
        fontSize: global.config.site.best_seller_product_title_font_size,
        fontWeight: global.config.site.best_seller_product_title_font_weight,
        color: global.config.site.best_seller_product_title_font_color,
        textDecoration: 'none',
    };

    const priceStyle = {
        fontSize: global.config.site.best_seller_product_price_font_size,
        fontWeight: global.config.site.best_seller_product_price_font_weight,
        color: global.config.site.best_seller_product_price_font_color,
        textDecoration: 'none',
    };

    const innerDivStyle = {
        height: '80%',
        
    }

    const imageStyle = {
        borderRadius: global.config.site.best_seller_product_image_radius,
    }

    
    function GetImage(props){
        
        var class_use = 'mt-5';
        var xs = "12"
        var md = "6"
        var lg = "4"
        var xl = "4"

        return <Col className={class_use} xl={xl} md={md} lg={lg} xs={xs} key={props.product.id}>
        <div style={colStyle} className='text-center'>
            
            <Link style={{textDecoration:'none'}} to={`/product/${props.product.id}`}>
                <div style={innerDivStyle}>
                <Image style={imageStyle} alt={props.product.title} width="100%" height="100%" src={props.product.logo} /><br></br>
                </div>
                <div>
                <span style={titleStyle}>{props.product.title}</span><br></br>
                <span style={priceStyle}>${props.product.price}</span>
                </div>
            </Link>
        </div>  
        </Col>

    }

    
    return (
        
        <>
        {currentItems &&
              currentItems.map((item) => (
                <GetImage product={item} key={item.id} />
              ))}
        </>
    );
};

export default ShopItem;