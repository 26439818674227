import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Header from './header';
import '../config';
import ShopItem from './ShopItem';
import Stack from 'react-bootstrap/Stack';
import ReactPaginate from 'react-paginate';
import Footer from './footer';
import LoadingOverlay from 'react-loading-overlay-ts';
import HeaderOffer from './HeaderOffer';

const Posts = ({onAddItem, items, totalItems }) => {

    const [posts, setPosts] = useState([]);
    const [isActive, setActive] = useState(true)
    const [itemOffset, setItemOffset] = useState(0);

    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    let itemsPerPage = global.config.shop.items_per_page;
    
    let itemOffsetset = itemOffset + 1;
    
    const endOffset = parseInt(itemOffset) + parseInt(itemsPerPage);

    let itemEndset = endOffset;
    if (endOffset > posts.length){
        itemEndset = posts.length;
    }
    
    
    const currentItems = posts.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(posts.length / itemsPerPage);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % posts.length;
       
        setItemOffset(newOffset);
    };


    useEffect(() => {
        const getPosts = async () => {
            try {
            const resp = await fetch(`${global.config.api.products_url}?site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
            setActive(false);
            } catch {
                
            }
        };

        getPosts();
    }, []);



    const h1Style = {
        padding: global.config.site.page_heading_padding,
    }   
    
    
    
      const rowHeaderStyle = {
        backgroundColor: global.config.site.pages_header_background_color,
        backgroundImage: global.config.site.pages_header_background_image,
        backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
        padding: global.config.site.pages_header_padding,
        color: global.config.site.pages_header_font_color,
        fontSize: global.config.site.pages_header_font_size,
        fontWeight: global.config.site.pages_header_font_weight,
        backgroundSize: global.config.site.pages_header_background_size,
        backgroundPosition: global.config.site.pages_header_background_position,
        backgroundRepeat: global.config.site.pages_header_background_repeat,
        position: global.config.site.pages_header_position,
        height: global.config.site.pages_header_height,
        margin: global.config.site.pages_header_margin,
      }
    
      const rowStyle = {
            backgroundColor: global.config.site.page_container_background_color,
            backgroundImage: global.config.site.page_container_background_image,
            padding: global.config.site.page_container_padding,
        }

    const showingStyle = {
        fontSize: global.config.site.shop_showing_style_font_size,
        fontWeight: global.config.site.shop_showing_style_font_weight,
        color: global.config.site.shop_showing_style_font_color
    }



    return (
        <>
        <style>
            {`
                a {
                    color: ${global.config.site.pageination_font_color};

                }

                .pagination {
                    text-decoration: none;
                  }
                  
                  .pagination li {
                    color: ${global.config.site.pageination_font_color};
                    font-size: ${global.config.site.pageination_font_size};
                    float: left;
                    padding: 8px 16px;
                    text-decoration: none;
                    border: 1px solid ${global.config.site.pageination_border_color};
                  }

                  .pagination li a {
                    color: ${global.config.site.pageination_font_color};
                    font-size: ${global.config.site.pageination_font_size};
                    text-decoration: none;
                  }

                  .pagination li.selected {
                    background-color: ${global.config.site.pageination_background_color};
                    color: ${global.config.site.pageination_font_selected_color}!important;
                  }
                  
                  .pagination li:first-child {
                    border-top-left-radius: ${global.config.site.pageination_border_radius};
                    border-bottom-left-radius: ${global.config.site.pageination_border_radius};
                  }
                  
                  .pagination li:last-child {
                    border-top-right-radius: ${global.config.site.pageination_border_radius};
                    border-bottom-right-radius: ${global.config.site.pageination_border_radius};
                  }

                  .pagination li:hover:not(.selected) {
                    background-color: ${global.config.site.pageination_hover_color};
                    color: ${global.config.site.pageination_font_hover_color}!important;
                }

            `}
        </style>
        <HeaderOffer />
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Row style={rowStyle} className='g-0'>

                <div style={rowHeaderStyle}>
                    <center><h1 style={h1Style}>{global.config.site.shop_header_text}</h1></center>
                </div> 

                <Container className={global.config.site.inner_container_size}>
        <LoadingOverlay
            active={isActive}
            spinner
            text='Loading...'
        >
            
            <Row className='mt-5'>
                <Col xs="12">
                    <p style={showingStyle}>Showing {itemOffsetset} - {itemEndset} of {posts.length} results</p>
                </Col>
            <ShopItem onAddItem={onAddItem} items={items} currentItems={currentItems} />
            </Row>
            <Stack className="justify-content-center mt-5 mb-5" direction="horizontal" gap={5}>
            <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                className='pagination'
                nextClassName='pagination'
                previousClassName='pagination'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="<"
                renderOnZeroPageCount={null}
            />
            </Stack>
            
        </LoadingOverlay>
        </Container>
        </Row>
        <Footer />
        </>
        
    );
};

export default Posts;