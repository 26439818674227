
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import { Row } from 'react-bootstrap';
import '../../config';

export const Footer = () => {
    
    function GetLogo(){
        if (global.config.site.nav_logo){
            
            return <Image className="pb-5" height={global.config.site.footer_logo_height} src={global.config.site.nav_logo} />;
        } else {
            if (global.config.site.nav_title){
                return <Navbar.Brand href="/"><h3>{global.config.site.nav_title}</h3></Navbar.Brand>;
            }
        }
    }

    const navLinkStyle = {
        fontSize: global.config.site.footer_nav_link_font_size,
        fontColor: global.config.site.footer_nav_link_font_color,
        fontWeight: global.config.site.footer_nav_link_font_weight,

    };

    const pStyle = {
        fontSize: global.config.site.footer_nav_copyright_font_size,
        fontColor: global.config.site.footer_nav_copyright_font_color,
        fontWeight: global.config.site.footer_nav_copyright_font_weight,
    }

    const containerStyle = {
        backgroundColor: global.config.site.footer_container_background_color,
        backgroundImage: global.config.site.footer_container_background_image,
        backgroundBlendMode: global.config.site.footer_container_background_blend_mode,
        padding: global.config.site.footer_container_padding,
        color: global.config.site.footer_container_font_color,
        fontSize: global.config.site.footer_container_font_size,
        fontWeight: global.config.site.footer_container_font_weight,
        backgroundSize: global.config.site.footer_container_background_size,
        backgroundPosition: global.config.site.footer_container_background_position,
        backgroundRepeat: global.config.site.footer_container_background_repeat,
        position: global.config.site.footer_container_position,
        height: global.config.site.footer_container_height,
        margin: global.config.site.footer_container_margin,
    }

    return (
            <Row style={containerStyle} className='g-0 footer'>
            <Container className={global.config.site.inner_container_size}>
                <Stack className="justify-content-center pt-5" direction="horizontal" gap={3}>
                    <GetLogo></GetLogo>
                </Stack>
                
                <div className='d-none d-md-block'>
                <Stack className="justify-content-center mb-2" direction="horizontal" gap={3}>
                    <Navbar>
                        <Nav className="justify-content-center">
                            <Nav.Link style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                            <Nav.Link style={navLinkStyle} href="/shop">{'Shop Online'.toUpperCase()}</Nav.Link>
                            <Nav.Link style={navLinkStyle} href="/terms-and-conditions">{'Terms And Conditions'.toUpperCase()}</Nav.Link>
                            <Nav.Link style={navLinkStyle} href="/privacy-policy">{'Privacy Policy'.toUpperCase()}</Nav.Link>
                            <Nav.Link style={navLinkStyle} href="/shipping-policy">{'Shipping Policy'.toUpperCase()}</Nav.Link>
                            <Nav.Link style={navLinkStyle} href="/contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                        </Nav>
                    </Navbar>   
                </Stack>
                </div>

                <div className='d-block d-md-none  text-center'>
                    <Navbar className='d-flex justify-content-center'>
                        <Nav className="g-0">
                            <Stack className="mb-2 mx-auto" gap={3}>
                                
                                        <Nav.Link style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                                        <Nav.Link style={navLinkStyle} href="/shop">{'Shop Online'.toUpperCase()}</Nav.Link>
                                        <Nav.Link style={navLinkStyle} href="/terms-and-conditions">{'Terms And Conditions'.toUpperCase()}</Nav.Link>
                                        <Nav.Link style={navLinkStyle} href="/privacy-policy">{'Privacy Policy'.toUpperCase()}</Nav.Link>
                                        <Nav.Link style={navLinkStyle} href="/shipping-policy">{'Shipping Policy'.toUpperCase()}</Nav.Link>
                                        <Nav.Link style={navLinkStyle} href="/contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                                    
                            </Stack>
                    </Nav>
                    </Navbar>   
                </div>
                
                <Stack className="justify-content-center" direction="horizontal" gap={5}>
                    <center><p style={pStyle}>Copyright © {global.config.site.nav_title}. All Rights Reserved</p></center>
                </Stack>
                <Stack className="justify-content-center" direction="horizontal" gap={5}>
                    <Image height={global.config.site.footer_cards_logo_height} src="/CreditCards.png"></Image>
                </Stack>
            </Container>
            </Row>
    );
};
