
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Image from 'react-bootstrap/Image';

import '../../config';

export const Discover = () => {
    
    const pStyle = {
        fontSize: global.config.site.discover_text_font_size,
        fontWeight: global.config.site.discover_text_font_weight,
    };

    const h1Style = {
        fontSize: global.config.site.discover_title_font_size,
        fontWeight: global.config.site.discover_title_font_weight,
    };

    const buttonStyle = {
        fontSize: global.config.site.discover_button_font_size,
        fontWeight: global.config.site.discover_button_font_weight,
    };

    const imgStyle =  {
        width: global.config.site.discover_image_width,
        borderRadius: global.config.site.discover_image_border_radius,
        boxShadow: global.config.site.discover_image_box_shadow
    }

    const rowStyle = {
        border: global.config.site.discover_border,
        borderRadius: global.config.site.discover_border_radius,
        padding: global.config.site.discover_padding,
    }

    const containerStyle = {
        backgroundColor: global.config.site.discover_container_style_background_color,
        boxShadow: global.config.site.discover_container_style_box_shadow,
        position: global.config.site.discover_container_style_position,
    }

    const rowMainStyle = {
        marginTop: global.config.site.discover_row_main_style_margin_top,
        
    }

    return (
        <Row style={rowMainStyle} className='g-0'>
        <Container style={containerStyle} className={global.config.site.inner_container_size}>
            <div className='mt-5 p-2'>
            <Row  style={rowStyle} >
                    <Col className='text-center' xs="12" md="6">
                        <Image style={imgStyle} src={global.config.site.discover_logo} />
                    </Col>
                    <Col xs="12" md="6">
                        <h1 style={h1Style}>{global.config.site.discover_title}</h1>
                        <p className='pt-3' style={pStyle}>{global.config.site.discover_text}</p>
                        {global.config.site.discover_button_text !== '' && (
                        <Link to="/shop">
                            <Button className="pr-5 pl-5" style={buttonStyle} variant="outline-primary">{global.config.site.discover_button_text}</Button>{' '}
                        </Link>
                        )}

                    </Col>
                    
                </Row>
            </div>
        
        </Container>
        </Row>
    );
};
