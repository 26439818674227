
import '../config';
import Header from './header';
import Footer from './footer';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderOffer from './HeaderOffer';

const ThankYou = ({onAddItem, items, totalItems}) => {
    
    const h1Style = {
        padding: global.config.site.page_heading_padding,
    }   
    
    
    
      const rowHeaderStyle = {
        backgroundColor: global.config.site.pages_header_background_color,
        backgroundImage: global.config.site.pages_header_background_image,
        backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
        padding: global.config.site.pages_header_padding,
        color: global.config.site.pages_header_font_color,
        fontSize: global.config.site.pages_header_font_size,
        fontWeight: global.config.site.pages_header_font_weight,
        backgroundSize: global.config.site.pages_header_background_size,
        backgroundPosition: global.config.site.pages_header_background_position,
        backgroundRepeat: global.config.site.pages_header_background_repeat,
        position: global.config.site.pages_header_position,
        height: global.config.site.pages_header_height,
        margin: global.config.site.pages_header_margin,
      }
    
      const rowStyle = {
            backgroundColor: global.config.site.page_container_background_color,
            backgroundImage: global.config.site.page_container_background_image,
            padding: global.config.site.page_container_padding,
        }

        const innerContainerStyle = {
            backgroundColor: '',
            backgroundImage: '',
            padding: '50px 0px 50px 0px',
            border: '1px solid',
            borderRadius: '5px',
        }

        const thankYouTextStyle = {
            fontSize: '40px',
            fontWeight: '300',
            color: 'green',
            padding: '50px',
            
        }

    return (
        <div>
            <HeaderOffer />
            <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
            <Row style={rowStyle} className='g-0'>

                <div style={rowHeaderStyle}>
                    <center><h1 style={h1Style}>{global.config.site.thank_you_header_text}</h1></center>
                </div> 

                <Container className={global.config.site.inner_container_size}>
                    
                        <Row className='mt-5 mb-5 g-0'>
                            <Col xs="12">
                                <div style={innerContainerStyle}>
                                    <h3 style={thankYouTextStyle}>
                                        {global.config.site.thank_you_header_description}
                                    </h3>
                                </div>
                            </Col>
                        </Row>
                        
                    
                </Container>
                </Row>
            <Footer />
        </div>
    );
};

export default ThankYou;