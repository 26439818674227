import React from 'react';
import Nav from 'react-bootstrap/Nav';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'

import '../config';

const CartMenu = ({onAddItem, items, className, totalItems}) => {
    
    const navLinkStyle = {
        fontSize: global.config.site.nav_link_text_font_size,
        fontWeight: global.config.site.nav_link_text_font_weight,
        fontFamily: global.config.site.nav_link_text_font_family,
    };

    return (
        <Nav.Link className={className} style={navLinkStyle} href="/cart"><FontAwesomeIcon icon={faShoppingCart} /> {totalItems} {'Items'.toUpperCase()}</Nav.Link>
        
    );
};

export default CartMenu;