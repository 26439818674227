import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../config';
import Header from './header';
import { Row, Col, Image, Container, Button, Toast, Form } from 'react-bootstrap';
import Footer from './footer';
import RelatedProducts from './RelatedProducts';
import OrderShipping from './order_shipping';
import HeaderOffer from './HeaderOffer';

const Post = ({onAddItem, items, totalItems}) => {
    const [post, setPost] = useState({});
    const { id } = useParams();
    const [qty, setQty] = useState(1);

    useEffect(() => {
        const getPost = async () => {
            try {
            const resp = await fetch(`${global.config.api.product_url}?product_id=${id}&site=${global.config.api.site_id}`);
            const postResp = await resp.json();
            setPost(postResp);
            } catch {
                
            }
        };

        getPost();
    }, [id]);

    const [showToast, setShowToast] = useState(false);

    const setAdditem = () => {
        setShowToast(!showToast);
        
        var price_point = post.price.replace('$','');
        var sub_total = parseFloat(qty) * parseFloat(price_point);
        
        onAddItem({
            id,
            qty: qty,
            desc: post.description,
            title: post.title,
            price: price_point,
            sub_total: sub_total,
            logo: post.logo
        }, items);
    };

      const rowStyle = {
            backgroundColor: global.config.site.page_container_background_color,
            backgroundImage: global.config.site.page_container_background_image,
            padding: global.config.site.page_container_padding,
        }


        const productDetailStyle =  {
            padding: global.config.site.product_product_detail_style_padding,
            border: global.config.site.product_product_detail_style_border,
            borderColor: global.config.site.product_product_detail_style_border_color,
            borderRadius: global.config.site.product_product_detail_style_border_radius,
        }

        const poductImageStyle = {
            borderRadius: global.config.site.product_product_image_style_border_radius,
        }
        const pStyle = {
            fontSize: global.config.site.product_p_style_font_size,
            padding: '5px 15px',
        };
    
        const toastStyle = {
            width: global.config.site.product_toast_style_width,
            fontSize: global.config.site.product_toast_style_font_size,
            fontWeight: global.config.site.product_toast_style_font_weight,
            color: global.config.site.product_toast_style_font_color,
            backgroundColor: global.config.site.product_toast_style_background_color,
            border: global.config.site.product_toast_style_border,
            borderRadius: global.config.site.product_toast_style_border_radius,
        };

        const viewCartButton = {
            color: global.config.site.product_view_cart_button_font_color,
            border: global.config.site.product_view_cart_button_border,
            borderRadius: global.config.site.product_view_cart_button_border_radius,
            backgroundColor: global.config.site.product_view_cart_button_background_color,
            // float: global.config.site.product_view_cart_button_float,
        }

        const breadCrumbStyle = {
            fontSize: global.config.site.product_bread_crumb_style_font_size,
            fontWeight: global.config.site.product_bread_crumb_style_font_weight,
            color: global.config.site.product_bread_crumb_style_font_color,
            textDecoration: global.config.site.product_bread_crumb_style_text_decoration,
            textTransform: global.config.site.product_bread_crumb_style_text_transform,
            padding: global.config.site.product_bread_crumb_style_padding,
        }
        
        const productTitleStyle = {
            fontSize: global.config.site.product_product_title_style_font_size,
            fontWeight: global.config.site.product_product_title_style_font_weight,
            color: global.config.site.product_product_title_style_font_color,
        }

        const productPriceStyle = {
            fontSize: global.config.site.product_product_price_style_font_size,
            fontWeight: global.config.site.product_product_price_style_font_weight,
            color: global.config.site.product_product_price_style_font_color,
        }

        const productDetailTextStyle = {
            fontSize: global.config.site.product_product_detail_text_style_font_size,
            fontWeight: global.config.site.product_product_detail_text_style_font_weight,
            color: global.config.site.product_product_detail_text_style_font_color,
        }
        const productDetailParagraphStyle = {
            fontSize: global.config.site.product_product_detail_paragraph_style_font_size,
            fontWeight: global.config.site.product_product_detail_paragraph_style_font_weight,
            color: global.config.site.product_product_detail_paragraph_style_font_color,
        }

    if (!Object.keys(post).length) return <div />;

    return (
        <>
        <HeaderOffer />
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>
        <Row style={rowStyle} className='g-0 mb-5'>

                
                <Container className={global.config.site.inner_container_size}>
                <Row className='mt-5 g-0'>
                    <Col xs="12" >
                            <Toast style={toastStyle} show={showToast} >
                        
                                <Toast.Body>
                                    <span>
                                    Item was successfully add to your cart!
                                    </span>
                                    <span className='mr-5 m-auto'>
                                        <Link className='float-right text-right m-auto' to="/cart">
                                                <Button style={viewCartButton} className='float-right text-right m-auto'>View Cart</Button>
                                            </Link>
                                    </span>
                                </Toast.Body>
                                
                            </Toast>
                    </Col>
                </Row>
                <Row className='mt-5 mb-5'>
                    <Col xs="12">
                        <Row className=''>
                            <Col className='' md="6" xs="12">
                                <Image style={poductImageStyle} alt={post.title} width="100%" src={post.logo} />
                            </Col>
                            <Col className='' md="6" xs="12">
                            
                                <Link to="/home" style={breadCrumbStyle}>Home</Link> / 
                                <Link to="/shop" style={breadCrumbStyle}> Shop</Link> / 
                                <Link style={breadCrumbStyle}> {post.title}</Link>
                            
                                    <h3 style={productTitleStyle}>{post.title}</h3>
                                    <p style={productPriceStyle}>${post.price}</p>
                                    <Form>
                                        <Form.Group as={Row}>
                                            <Col sm="3">
                                                <Form.Control type='number' min='1' value={qty} onChange={event => setQty(event.target.value.replace(/\D/,''))}/>
                                            </Col>
                                            <Col sm="9">
                                                <Button type="button" className="pr-5 pl-5 btn-sm" style={pStyle} variant="outline-primary" onClick={() => setAdditem()}>
                                                    Add to cart
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                            </Col>
                        </Row>
                    </Col>
                    <Col className='mt-5' xs="12">
                        <div style={productDetailStyle}>
                            <h3 style={productDetailTextStyle}>Product Details</h3>
                            <p style={productDetailParagraphStyle}>{post.description}</p>
                        </div>
                    </Col>

                </Row>
            
            
                    
        </Container>
        </Row>

        {global.config.site.show_related_products_pages === '1' && (
                <RelatedProducts />
        )}

        {global.config.site.show_order_shipping_pages === '1' && (
                <OrderShipping />
        )}

        <Footer />
        </>
    );
};

export default Post;