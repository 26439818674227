

import CartItem from "./CartItem";
import { Link } from "react-router-dom";
import { Row, Col, Container, Stack, Button } from 'react-bootstrap';
import Header from './header';
import '../config';
import Footer from "./footer";
import OrderShipping from "./order_shipping";
import HeaderOffer from "./HeaderOffer";

const Cart = ({ items, onRemoveItem, onAddItem, onUpdateQtyItem, subTotal, totalItems }) => {

  const rowStyle = {
      backgroundColor: global.config.site.page_container_background_color,
      backgroundImage: global.config.site.page_container_background_image,
      padding: global.config.site.page_container_padding,
  }

  const h1Style = {
      padding: global.config.site.page_heading_padding,
  }

  const rowHeaderStyle = {
    backgroundColor: global.config.site.pages_header_background_color,
    backgroundImage: global.config.site.pages_header_background_image,
    backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
    padding: global.config.site.pages_header_padding,
    color: global.config.site.pages_header_font_color,
    fontSize: global.config.site.pages_header_font_size,
    fontWeight: global.config.site.pages_header_font_weight,
    backgroundSize: global.config.site.pages_header_background_size,
    backgroundPosition: global.config.site.pages_header_background_position,
    backgroundRepeat: global.config.site.pages_header_background_repeat,
    position: global.config.site.pages_header_position,
    height: global.config.site.pages_header_height,
    margin: global.config.site.pages_header_margin,
  }

  const tableTotalStyle = {
      border: global.config.site.cart_table_total_style_border,
      borderRadius: global.config.site.cart_table_total_style_border_radius,
      width: global.config.site.cart_table_total_style_width,
      fontSize: global.config.site.cart_table_total_style_font_size,
      fontWeight: global.config.site.cart_table_total_style_font_weight,
      color: global.config.site.table_cart_total_style_font_color,
  };

  const tableTotalTrStyle = {
    border: global.config.site.cart_table_total_tr_style_border,
    fontSize: global.config.site.cart_table_total_tr_style_font_size,
    color: global.config.site.table_cart_total_tr_style_font_color,
};

  const pStyle = {
      fontSize: global.config.site.cart_p_font_size,
      width: global.config.site.cart_p_width,
  };

  const emptyCartStyle = {
    backgroundColor: global.config.site.cart_empty_cart_style_background_color,
    color: global.config.site.cart_empty_cart_style_color,
    fontSize: global.config.site.cart_empty_cart_style_font_size,
    fontWeight: global.config.site.cart_empty_cart_style_font_weight,
    padding: global.config.site.cart_empty_cart_style_padding,
  }

  const buttonStyle = {

  }

  const tableHeadStyle = {
    fontSize: global.config.site.cart_table_head_style_font_size,
    fontWeight: global.config.site.cart_table_head_style_font_weight,
    color: global.config.site.cart_table_head_style_font_color,
    backgroundColor: global.config.site.cart_table_head_style_background_color,
    borderRadius: global.config.site.cart_table_head_style_border_radius,
    padding: global.config.site.cart_table_head_style_padding,
  }

  const innerContainerStyle = {
    padding: global.config.site.cart_inner_container_padding,
    backgroundColor: global.config.site.cart_inner_container_background_color,
    border: global.config.site.cart_inner_container_border,
    borderRadius: global.config.site.cart_inner_container_border_radius,
  }

  const tableStyle = {
    width: global.config.site.cart_table_style_width,
    fontSize: global.config.site.cart_table_style_font_size,
    fontWeight: global.config.site.cart_table_style_font_weight,
    color: global.config.site.cart_table_style_font_color,
    border: global.config.site.cart_table_style_border,
  }

  const subTotalPrice = {
    color: global.config.site.cart_sub_total_price_font_color,
    fontSize: global.config.site.cart_sub_total_price_font_size,
    fontWeight: global.config.site.cart_sub_total_price_font_weight,
  }

  const subTotalTotalPrice = {
    color: global.config.site.cart_sub_total_total_price_font_color,
    fontSize: global.config.site.cart_sub_total_total_price_font_size,
    fontWeight: global.config.site.cart_sub_total_total_price_font_weight,
  }

  const cartTotalsStyle = {
    fontSize: global.config.site.cart_cart_totals_style_font_size,
    color: global.config.site.cart_cart_totals_style_font_color,
  }

  return (
    <>
        <HeaderOffer />
        <Header onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header>


        <Row style={rowStyle} className='g-0 mb-5'>
            <div style={rowHeaderStyle}>
                <center><h1 style={h1Style}>{global.config.site.cart_header_text}</h1></center>
            </div> 

          <Container className={global.config.site.inner_container_size}>
            
            <Row style={innerContainerStyle} className='m-3'>

              {items.length === 0 && (
                <Col xs="12">
                  <p style={emptyCartStyle}>Your cart is currently empty.</p>
                  <Link style={{textDecoration:'none'}} to='/shop'>
                      <Button className="pr-5 pl-5" style={buttonStyle} variant="outline-primary">{global.config.site.cart_return_button_text}</Button>{' '}
                  </Link>

                </Col>
              )}

              {items.length > 0 && (
                <Col className="d-none d-md-block" xs="12">
                <table cellPadding={global.config.site.table_cell_padding} cellSpacing={global.config.site.table_cell_spacing} style={tableStyle}>
                  <thead >
                    <tr style={tableHeadStyle}>
                      <th></th>
                      <th></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Sub Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    
                  {items &&
                          items.map((item) => (
                            <CartItem item={item} onRemoveItem={onRemoveItem} onUpdateQtyItem={onUpdateQtyItem} key={item.id} />
                          ))}
                  </tbody>
                </table>
                </Col>
                )}
                <div className="d-block d-md-none">
                  <Row className="">
                      
                        {items &&
                          items.map((item) => (
                            <CartItem col='true' item={item} onRemoveItem={onRemoveItem} onUpdateQtyItem={onUpdateQtyItem} key={item.id} />
                          ))}
                  </Row>
                </div>
              
            </Row>
            {items.length > 0 && (
            <Row style={innerContainerStyle} className='m-3'>

            
                      <h1 style={cartTotalsStyle}>Cart Totals</h1>
                      <Row>
                        <Col xs="12">
                         
                            <table style={tableTotalStyle} cellPadding={global.config.site.table_cell_padding} cellSpacing={global.config.site.table_cell_spacing} borderless='true' >
                              <tbody>
                                <tr style={tableTotalTrStyle }>
                                  <td>SubTotal</td>
                                  <td style={subTotalPrice}>${subTotal.toFixed(2)}</td>
                                </tr>
                                <tr style={tableTotalTrStyle }>
                                  <td>Total</td>
                                  <td style={subTotalTotalPrice}><b>${subTotal.toFixed(2)}</b></td>
                                </tr>
                              </tbody>
                            </table>
                          
                      </Col>
                      </Row> 
                      <Row className="mt-5">
                        <Col xs="12">
                        <Stack className="justify-content-center" direction="horizontal" gap={5}>
                          <Link to='/checkout' style={pStyle} >
                            <Button className="pr-5 pl-5" style={pStyle} variant="outline-primary">PROCEED TO CHECKOUT</Button>{' '}
                          </Link>
                        </Stack>
                        </Col>
                      </Row>
              
            </Row>
            )}
        </Container>
        </Row>
        
        

        {global.config.site.show_order_shipping_pages === '1' && (
                <OrderShipping />
        )}

        <Footer />
        </>
  );
};

export default Cart;
