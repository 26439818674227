
import { Footer as Footer1 } from '../footers/1/footer';
import { Footer as Footer2 } from '../footers/2/footer';
import { Footer as Footer3 } from '../footers/3/footer';
import { Footer as Footer4 } from '../footers/4/footer';
import { Footer as Footer5 } from '../footers/5/footer';
import { Footer as Footer6 } from '../footers/6/footer';
import { Footer as Footer7 } from '../footers/7/footer';
import '../config';

const Footer = () => {
    
    return (
        <>
        
        <style type="text/css">
            {`
            .footer .nav-link.active{
                color: ${global.config.site.footer_link_text_active_color}!important;
            }
            .footer .nav-link {
                color: ${global.config.site.footer_link_text_color}!important;
            }
            .footer .navbar-nav .nav-link:hover {
                color: ${global.config.site.footer_link_text_hover_color}!important;
            }
            `}
        </style>

        { global.config.site.footer === '1' && (
            <Footer1 />
        )}

        { global.config.site.footer === '2' && (
            <Footer2 />
        )}

        { global.config.site.footer === '3' && (
            <Footer3 />
        )}

        { global.config.site.footer === '4' && (
            <Footer4 />
        )}

        { global.config.site.footer === '5' && (
            <Footer5 />
        )}
        
        { global.config.site.footer === '6' && (
            <Footer6 />
        )}

        { global.config.site.footer === '7' && (
            <Footer7 />
        )}

        { global.config.site.footer === undefined && (
            <Footer1 />
        )}
        </>
        
    );
};

export default Footer;