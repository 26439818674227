
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faRefresh, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import '../../config';

export const Footer = () => {
    
    const navLinkStyle = {
        fontSize: global.config.site.footer_nav_link_font_size,
        fontColor: global.config.site.footer_nav_link_font_color,
        fontWeight: global.config.site.footer_nav_link_font_weight,
        padding: global.config.site.footer_nav_link_padding,
    };

    const containerStyle = {
        backgroundColor: global.config.site.footer_container_background_color,
        backgroundImage: global.config.site.footer_container_background_image,
        backgroundBlendMode: global.config.site.footer_container_background_blend_mode,
        padding: global.config.site.footer_container_padding,
        color: global.config.site.footer_container_font_color,
        fontSize: global.config.site.footer_container_font_size,
        fontWeight: global.config.site.footer_container_font_weight,
        backgroundSize: global.config.site.footer_container_background_size,
        backgroundPosition: global.config.site.footer_container_background_position,
        backgroundRepeat: global.config.site.footer_container_background_repeat,
        position: global.config.site.footer_container_position,
        height: global.config.site.footer_container_height,
        margin: global.config.site.footer_container_margin,
    }

    const headerStyle = {
        fontSize: global.config.site.footer_header_font_size,
        fontWeight: global.config.site.footer_header_font_weight,
        color: global.config.site.footer_header_font_color,
    }

    const iconStyle = {
        color: global.config.site.footer_icon_color,
        height: global.config.site.footer_icon_height,
    }

    const pStyle = {
        fontSize: global.config.site.footer_nav_copyright_font_size,
        fontColor: global.config.site.footer_nav_copyright_font_color,
        fontWeight: global.config.site.footer_nav_copyright_font_weight,
    }

    const container2Style = {
        backgroundColor: global.config.site.footer_container_2_background_color,
        backgroundImage: global.config.site.footer_container_2_background_image,
        backgroundBlendMode: global.config.site.footer_container_2_background_blend_mode,
        padding: global.config.site.footer_container_2_padding,
        color: global.config.site.footer_container_2_font_color,
        fontSize: global.config.site.footer_container_2_font_size,
        fontWeight: global.config.site.footer_container_2_font_weight,
        backgroundSize: global.config.site.footer_container_2_background_size,
        backgroundPosition: global.config.site.footer_container_2_background_position,
        backgroundRepeat: global.config.site.footer_container_2_background_repeat,
        position: global.config.site.footer_container_2_position,
        height: global.config.site.footer_container_2_height,
        margin: global.config.site.footer_container_2_margin,
    }

    return (
        <>
            <Row style={containerStyle} className='g-0 footer'>
            <Container className={global.config.site.inner_container_size}>
                <Row>
                    <Col xs='12' lg='6'>
                        <Row>
                            <Col xs="12">
                                <span style={headerStyle}>{global.config.site.footer_contact_text}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="2"><FontAwesomeIcon style={iconStyle}  icon={faMapMarkedAlt} /></Col>
                            <Col xs="10">{global.config.site.company.name} {global.config.site.company.address}</Col>
                        </Row>
                        <Row>
                            <Col xs="2"><FontAwesomeIcon style={iconStyle}  icon={faPhone} /></Col>
                            <Col xs="10">{global.config.site.company.customer_support_number}</Col>
                        </Row>
                        <Row>
                            <Col xs="2"><FontAwesomeIcon style={iconStyle}  icon={faEnvelope} /></Col>
                            <Col xs="10">{global.config.site.company.email}</Col>
                        </Row>
                        <Row>
                            <Col xs="2"><FontAwesomeIcon style={iconStyle}  icon={faRefresh} /></Col>
                            <Col xs="10">HOURS OF OPERATION: 24/7</Col>
                        </Row>
                    </Col>
                
                    <Col xs='12' lg='6'>
                        <Row>
                            <Col xs="12">
                                <span style={headerStyle}>{global.config.site.footer_links_text}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">

                                
                                    <Navbar className="g-0 p-0 m-0">
                                        <Nav className="g-0 p-0">
                                            <Stack className="g-0" gap={0}>
                                                <Nav.Link style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                                                <Nav.Link style={navLinkStyle} href="/shop">{'Shop Online'.toUpperCase()}</Nav.Link>
                                                <Nav.Link style={navLinkStyle} href="/terms-and-conditions">{'Terms And Conditions'.toUpperCase()}</Nav.Link>
                                                <Nav.Link style={navLinkStyle} href="/privacy-policy">{'Privacy Policy'.toUpperCase()}</Nav.Link>
                                                <Nav.Link style={navLinkStyle} href="/shipping-policy">{'Shipping Policy'.toUpperCase()}</Nav.Link>
                                                <Nav.Link style={navLinkStyle} href="/contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                                            </Stack>
                                        </Nav>
                                    </Navbar>   
                                
                            </Col>
                            
                        </Row>
                        
                    </Col>
                    
                </Row>
                
                
            </Container>
            </Row>
            <Row style={container2Style} className='g-0'>
            <Container className={global.config.site.inner_container_size}>
                
                <div>
                <Stack className="justify-content-center" direction="horizontal" gap={1}>
                    <center><p style={pStyle}>Copyright © {global.config.site.nav_title}. All Rights Reserved</p></center>
                </Stack>
                <Stack className="justify-content-center" direction="horizontal" gap={1}>
                    <Image height={global.config.site.footer_cards_logo_height} src="/CreditCards.png"></Image>
                </Stack>
                </div>
            </Container>
        </Row>
        </>
    );
};
