import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";
import { Row, Col, Container, Button } from 'react-bootstrap';

import Image from 'react-bootstrap/Image';

import '../config';

const BestSeller = () => {
    
    const containerStyle = {
        backgroundColor: global.config.site.best_seller_container_background_color,
        padding: global.config.site.best_seller_container_padding,
    }

    const colStyle = {
        border: global.config.site.best_seller_border,
        height: '100%',
        padding: global.config.site.best_seller_padding,
        backgroundColor: global.config.site.best_seller_title_background_color,
        borderRadius: global.config.site.best_seller_border_radius
    };

    const innerDivStyle = {
        height: '80%',
        
    }

    const imageStyle = {
        borderRadius: global.config.site.best_seller_product_image_radius,
    }

    const titleStyle = {
        color: global.config.site.best_seller_product_title_font_color,
        textDecoration: 'none',
        fontSize: global.config.site.best_seller_product_title_font_size,
        fontWeight: global.config.site.best_seller_product_title_font_weight,
    }
    const priceStyle = {
        color: global.config.site.best_seller_product_price_font_color,
        textDecoration: 'none',
        fontSize: global.config.site.best_seller_product_price_font_size,
        fontWeight: global.config.site.best_seller_product_price_font_weight,
    }

    const rowHeaderStyle = {
        backgroundColor: global.config.site.best_seller_header_background_color,
        backgroundImage: global.config.site.best_seller_header_background_image,
        padding: global.config.site.best_seller_header_padding,
        color: global.config.site.best_seller_header_font_color,
        fontSize: global.config.site.best_seller_header_font_size,
        fontWeight: global.config.site.best_seller_header_font_weight,
    }

    const buttonStyle = {

    }

    function GetImage(props){
        var class_use = '';
        var xs = "12"
        var md = "12"
        var lg = "12"
        var xl = "12"

        if (global.config.site.best_seller_display === '4'){
            xs = "12"
            md = "6"
            lg = "6"
            xl = "3"
        }
        if (global.config.site.best_seller_display === '3'){
            xs = "12"
            md = "4"
            lg = "4"
            xl = "4"
        }
        if (global.config.site.best_seller_display === '2'){
            xs = "12"
            md = "6"
            lg = "6"
            xl = "6"
        }
        

        return <Col className={class_use} xl={xl} md={md} lg={lg} xs={xs} key={props.product.id}>
                    <div style={colStyle} className='text-center'>
                        
                        <Link style={{textDecoration:'none'}} to={`/product/${props.product.id}`}>
                            <div style={innerDivStyle}>
                            <Image style={imageStyle} alt={props.product.title} width="100%" height="100%" src={props.product.logo} /><br></br>
                            </div>
                            <div>
                            <span style={titleStyle}>{props.product.title}</span><br></br>
                            <span style={priceStyle}>${props.product.price}</span>
                            </div>
                        </Link>
                    </div>  
                </Col>
    }
        

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            try{
            const resp = await fetch(`${global.config.api.products_url}?limit=${global.config.site.best_seller_display}&site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
            } catch {

            }
        };

        getPosts();
    }, []);


    return (
        <Row style={containerStyle} className='g-0'>
            <div style={rowHeaderStyle}>
                <center><h1>{global.config.site.best_seller_header_text}</h1></center>
            </div> 
        <Container className={global.config.site.inner_container_size}>
             
            <Row className='p-5'>
                
                {posts.map(post => (
                    
                    <GetImage product={post} key={post.id} />
                ))}

                <Col sm="12" className='mt-5'>
                    <center>
                        <Link style={{textDecoration:'none'}} to='/shop'>
                            <Button className="pr-5 pl-5" style={buttonStyle} variant="outline-primary">{global.config.site.best_seller_more_button_text}</Button>{' '}
                        </Link>
                    </center>
                </Col>
            </Row>

            

            
        </Container>
        </Row>
    );
};

export default BestSeller;