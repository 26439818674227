
import { Hero as Hero1 } from '../heros/1/hero';
import { Hero as Hero2 } from '../heros/2/hero';
import { Hero as Hero3 } from '../heros/3/hero';
import '../config';

const Hero = () => {
    
    return (
        <>
        
            { global.config.site.hero_type === '1' && (
                <Hero1 />
            )}
            { global.config.site.hero_type === '2' && (
                <Hero2 />
            )}
            { global.config.site.hero_type === '3' && (
                <Hero3 />
            )}
        </>
        
    );
};

export default Hero;