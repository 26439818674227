
import { Header as Header1 } from '../headers/1/header';
import { Header as Header2 } from '../headers/2/header';
import { Header as Header3 } from '../headers/3/header';

import '../config';

const Header = ({onAddItem, items, totalItems}) => {
    
    return (
        <>
        <style type="text/css">
            {`
            .navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
                color: ${global.config.site.nav_link_text_active_color};
            }
            .nav-link{
                color: ${global.config.site.nav_link_text_color};
            }
            .navbar-nav .nav-link:hover {
                color: ${global.config.site.nav_link_text_hover_color};
            }
            `}
        </style>
        { global.config.site.header === '1' && (
            <Header1 onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header1>
        )}
        { global.config.site.header === '2' && (
            <Header2 onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header2>
        )}
        { global.config.site.header === '3' && (
            <Header3 onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header3>
        )}
        { global.config.site.header === undefined && (
            <Header1 onAddItem={onAddItem} items={items} totalItems={totalItems} ></Header1>
        )}
        </>
        
    );
};

export default Header;