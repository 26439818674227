import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Container} from 'react-bootstrap';

import Image from 'react-bootstrap/Image';

import '../config';

const RelatedProducts = () => {
    
    const titleStyle = {
        color: global.config.site.related_products_product_title_font_color,
        textDecoration: 'none',
        fontSize: global.config.site.related_products_product_title_font_size,
        fontWeight: global.config.site.related_products_product_title_font_weight,
    }
    const priceStyle = {
        color: global.config.site.related_products_product_price_font_color,
        textDecoration: 'none',
        fontSize: global.config.site.related_products_product_price_font_size,
        fontWeight: global.config.site.related_products_product_price_font_weight,
    }

    const colStyle = {
        border: global.config.site.best_seller_border,
        height: '100%',
        padding: global.config.site.best_seller_padding,
        backgroundColor: global.config.site.best_seller_title_background_color,
        borderRadius: global.config.site.best_seller_border_radius
    };

    const containerStyle = {
        backgroundColor: global.config.site.related_products_container_background_color,
        padding: global.config.site.related_products_container_padding,
    }

    const rowHeaderStyle = {
        backgroundColor: global.config.site.related_products_header_background_color,
        backgroundImage: global.config.site.related_products_header_background_image,
        padding: global.config.site.related_products_header_padding,
        color: global.config.site.related_products_header_font_color,
        fontSize: global.config.site.related_products_header_font_size,
        fontWeight: global.config.site.related_products_header_font_weight,
    }

    const innerDivStyle = {
        height: '80%',
        
        
    }

    const imageStyle = {
        borderRadius: global.config.site.best_seller_product_image_radius,
    }

    function GetImage(props){
        
        var class_use = '';
        var xs = "12"
        var md = "12"
        var lg = "12"
        var xl = "12"

        if (global.config.site.best_seller_display === '4'){
            xs = "12"
            md = "6"
            lg = "6"
            xl = "3"
        }
        if (global.config.site.best_seller_display === '3'){
            xs = "12"
            md = "4"
            lg = "4"
            xl = "4"
        }
        if (global.config.site.best_seller_display === '2'){
            xs = "12"
            md = "6"
            lg = "6"
            xl = "6"
        }

            return <Col className={class_use} xl={xl} md={md} lg={lg} xs={xs} key={props.product.id}>
            <div style={colStyle} className='text-center'>
                
                <Link style={{textDecoration:'none'}} to={`/product/${props.product.id}`}>
                    <div style={innerDivStyle}>
                    <Image style={imageStyle} alt={props.product.title} width="100%" height="100%" src={props.product.logo} /><br></br>
                    </div>
                    <div>
                    <span style={titleStyle}>{props.product.title}</span><br></br>
                    <span style={priceStyle}>${props.product.price}</span>
                    </div>
                </Link>
            </div>  
        </Col>
        
    }

    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            try {
            const resp = await fetch(`${global.config.api.products_url}?limit=4&site=${global.config.api.site_id}`);
            const postsResp = await resp.json();
            setPosts(postsResp);
            } catch {

            }
        };

        getPosts();
    }, []);


    return (
        <Row style={containerStyle} className='g-0 mt-5'>
            <div style={rowHeaderStyle}>
                <center><h1>{global.config.site.related_products_header_text}</h1></center>
            </div> 
            
            <Container className={global.config.site.inner_container_size}>
             
            <Row >
                {posts.map(post => (
                    
                    <GetImage product={post} key={post.id} />
                ))}
            </Row>
            </Container>
        </Row>
    );
};

export default RelatedProducts;