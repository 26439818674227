
import Container from 'react-bootstrap/Container';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

import CartMenu from '../../components/cart_menu';
import '../../config';
import { useEffect } from 'react';

export const Header = ({onAddItem, items, totalItems}) => {
    
    
    const location = useLocation();
    useEffect(() => {
        
    }, [location]);

    function GetLogo(){
        if (global.config.site.nav_logo){
            
            return <Image className={global.config.site.header_logo_spacing} height={global.config.site.header_logo_height} src={global.config.site.nav_logo} />;
        } else {
            if (global.config.site.nav_title){
                return <Navbar.Brand href="/"><h3>{global.config.site.nav_title}</h3></Navbar.Brand>;
            }
        }
    }

    const navLinkStyle = {
        fontSize: global.config.site.nav_link_text_font_size,
        fontWeight: global.config.site.nav_link_text_font_weight,
        fontFamily: global.config.site.nav_link_text_font_family,
    };

    const containerStyle = {
        backgroundColor: global.config.site.header_background_color,
        backgroundImage: global.config.site.header_background_image,
        
    }
    
    const navBarStyle = {
        margin: 0
    }

    return (
        <Row className='g-0'>
        <Navbar style={navBarStyle} className="justify-content-center gt-0 no-gutters p-0" expand="md">
        <Container style={containerStyle} fluid>
            <Container className={global.config.site.inner_container_size} >
            <Stack direction="horizontal" gap={3}>
                
                <div className="px-2">
                <GetLogo></GetLogo>
                </div>
                <div className="px-2 ms-auto">

                </div>
                <div className="px-2">
                    
                
                
                        <CartMenu className='d-block d-md-none pe-3' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                        
                    
                        <Navbar className="justify-content-center d-none d-md-block" id="basic-navbar-nav">
                    
                            <Nav activeKey={location.pathname} className="justify-content-center">
                            
                                <Nav.Link className='me-2' style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                                
                                <Nav.Link className='me-2' style={navLinkStyle} href="/shop">{'Shop'.toUpperCase()}</Nav.Link>
                                <Nav.Link className='me-2' style={navLinkStyle} href="/cart">{'Cart'.toUpperCase()}</Nav.Link>
                                <Nav.Link className='me-2' style={navLinkStyle} href="/contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                                <CartMenu className='d-none d-md-block' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                                
                            </Nav>
                        
                        </Navbar>
                        
                </div>
                <div className=''>
                    <Navbar.Toggle aria-controls="basic-navbar-nav2" className=''>
                    
                    </Navbar.Toggle>
                </div>
            </Stack>
            
            </Container>
            
        </Container>
        <Container className='d-md-none' fluid>
        <Row >
            
            <Container>
            <Navbar.Collapse className="justify-content-center" id="basic-navbar-nav2">
        
                <Nav activeKey={location.pathname} className="justify-content-center d-md-none">
                
                    <Nav.Link className='me-2' style={navLinkStyle} href="/">{'Home'.toUpperCase()}</Nav.Link>
                    <Nav.Link className='me-2' style={navLinkStyle} href="/cart">{'Cart'.toUpperCase()}</Nav.Link>
                    <Nav.Link className='me-2' style={navLinkStyle} href="/shop">{'Shop'.toUpperCase()}</Nav.Link>
                    <Nav.Link className='me-2' style={navLinkStyle} href="/#contact-us">{'Contact Us'.toUpperCase()}</Nav.Link>
                    <CartMenu className='d-none d-md-block' onAddItem={onAddItem} items={items} totalItems={totalItems}></CartMenu>
                    
                </Nav>
            
            </Navbar.Collapse>
            </Container>  
                
        </Row>
        </Container>
        </Navbar>
        </Row>
    );
};
