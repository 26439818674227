import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import '../config';

const HeaderOffer = () => {
    
    const containerStyle = {
        backgroundColor: global.config.site.header_offer_background_color,
    }

    const spanStyle = {
        
        color: global.config.site.header_offer_text_color,
        fontSize: global.config.site.header_offer_font_size,
        paddingRight: '15px',
    };


    return (
        <>
        {global.config.site.header_offer_use === "1"  && (
        <Container style={containerStyle} fluid className='p-2'>
            <center><span style={spanStyle}>{global.config.site.header_offer_text}</span></center>
        </Container>
        )}
        {global.config.site.header_offer_use === "2"  && (
        <Container style={containerStyle} fluid className='p-2'>
            <span style={spanStyle}>{global.config.site.header_offer_text}</span>
        </Container>
        )}
        {global.config.site.header_offer_use === "3"  && (
        <Container style={containerStyle} fluid className='p-2'>
            <Container className={global.config.site.inner_container_size}>
                <Row className='pr-2'>
                    <Col className='pr-2'>
                        
                        <FontAwesomeIcon className='pr-2' icon={faPhone} style={spanStyle} />
                        <span style={spanStyle}>{global.config.site.company.customer_support_number}</span>
                        
                        <FontAwesomeIcon style={spanStyle} icon={faEnvelope} /> <span style={spanStyle}>{global.config.site.company.email}</span>
                    </Col>
                    
                    
                </Row>
            </Container>
        </Container>
        )}
        </>
        
    );
};

export default HeaderOffer;