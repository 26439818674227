import { Row, Col, Container} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt, faMobileAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '../../config';

export const ContactUs = ({is_home}) => {
    
    // content boxes
    const colBorderStyle = {
        border: global.config.site.contact_us_content_box_border,
        borderRadius: global.config.site.contact_us_content_box_border_radius,
        padding: global.config.site.contact_us_content_box_padding,
        boxShadow: global.config.site.contact_us_content_box_shadow,
        height: '100%',
    };

    // header 
    const rowHeaderStyle = {
        backgroundColor: global.config.site.pages_header_background_color,
        backgroundImage: global.config.site.pages_header_background_image,
        backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
        padding: global.config.site.pages_header_padding,
        color: global.config.site.pages_header_font_color,
        fontSize: global.config.site.pages_header_font_size,
        fontWeight: global.config.site.pages_header_font_weight,
        backgroundSize: global.config.site.pages_header_background_size,
        backgroundPosition: global.config.site.pages_header_background_position,
        backgroundRepeat: global.config.site.pages_header_background_repeat,
        position: global.config.site.pages_header_position,
        height: global.config.site.pages_header_height,
        margin: global.config.site.pages_header_margin,
      }
    
      const rowStyle = {
            backgroundColor: global.config.site.page_container_background_color,
            backgroundImage: global.config.site.page_container_background_image,
            padding: global.config.site.page_container_padding,
        }

    const rowHomeHeaderStyle = {
        // backgroundColor: global.config.site.contact_us_header_background_color,
        // backgroundImage: global.config.site.contact_us_header_background_image,
        backgroundBlendMode: global.config.site.pages_header_background_blend_mode,
        padding: global.config.site.pages_header_padding,
        color: global.config.site.pages_header_font_color,
        fontSize: global.config.site.pages_header_font_size,
        fontWeight: global.config.site.pages_header_font_weight,
        backgroundSize: global.config.site.pages_header_background_size,
        backgroundPosition: global.config.site.pages_header_background_position,
        backgroundRepeat: global.config.site.pages_header_background_repeat,
        position: global.config.site.pages_header_position,
        height: global.config.site.pages_header_height,
        margin: global.config.site.pages_header_margin,
    }

    

    const rowHomeStyle = {
        // backgroundColor: global.config.site.contact_us_container_background_color,
        // backgroundImage: global.config.site.contact_us_container_background_image,
        padding: global.config.site.page_container_padding,
    }


    // inner container div (parent)
    const innerDivStyle = {
        border: global.config.site.contact_us_inner_container_border,
        borderRadius: global.config.site.contact_us_inner_container_border_radius,
    }

    // content title
    const titleStyle = {
        fontSize: global.config.site.contact_us_content_title_font_size,
        fontWeight: global.config.site.contact_us_content_title_font_weight,
        color: global.config.site.contact_us_content_title_font_color,
    }
    // content icon
    const iconStyle = {
        color: global.config.site.contact_us_icon_color,
        height: global.config.site.contact_us_icon_height,
    }

    // content information
    const infoStyle = {
        fontSize: global.config.site.contact_us_content_info_font_size,
        fontWeight: global.config.site.contact_us_content_info_font_weight,
        color: global.config.site.contact_us_content_info_font_color,
    }

    // page heading!
    const h1Style = {
        padding: global.config.site.contact_us_heading_padding,
    }

    return (
       
        <Row style={is_home ? rowHomeStyle : rowStyle} className='mt-5 g-0'>
        
            <div style={is_home ? rowHomeHeaderStyle : rowHeaderStyle}>
                <center><h1 style={h1Style}>{global.config.site.contact_us_header_text}</h1></center>
            </div> 

            <Container className={global.config.site.inner_container_size} id='contact-us'>
            
                    <Row className="mb-5" style={innerDivStyle}>
                        <Col style={colBorderStyle} xs="12">
                            <Row className='m-3 p-4' >
                                <Col xs="12" sm="12">
                                    <center><FontAwesomeIcon style={iconStyle} icon={faMapMarkedAlt} /></center>
                                </Col>
                                <Col style={titleStyle} xs="12" sm="12">
                                    <center>Corp Address</center>
                                </Col>
                                <Col style={infoStyle} xs="12" sm="12">
                                    <center>{global.config.site.company.name}</center>
                                </Col>
                                <Col style={infoStyle} xs="12" sm="12">
                                    <center>{global.config.site.company.address}</center>
                                </Col>
                            </Row>
                        </Col>
                        <Col className='' xs="12" sm="12">
                            <Row className='mt-5 g-0' >
                                <Col className='pb-5 ' xs="12" lg="4">
                                    <Row className='p-4 me-lg-3' style={colBorderStyle} >
                                        <Col xs="12" sm="12">
                                            <center><FontAwesomeIcon style={iconStyle}  icon={faMapMarkedAlt} /></center>
                                        </Col>
                                        <Col style={titleStyle}  xs="12" sm="12">
                                            <center>Return Address</center>
                                        </Col>
                                        <Col style={infoStyle} xs="12" sm="12">
                                            <center>{global.config.site.company.return_address}</center>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className='pb-5 ' xs="12" lg="4">
                                    <Row className='p-4 me-lg-3' style={colBorderStyle}>
                                        <Col xs="12" sm="12">
                                            <center><FontAwesomeIcon style={iconStyle} icon={faMobileAlt} /></center>
                                        </Col>
                                        <Col style={titleStyle}  xs="12" sm="12">
                                            <center>Customer Support (24/7)</center>
                                        </Col>
                                        <Col style={infoStyle} xs="12" sm="12">
                                            <center>{global.config.site.company.customer_support_number}</center>
                                        </Col>
                                        <Col xs="12" sm="12">&nbsp;</Col>
                                    </Row>
                                </Col>
                                <Col className='pb-5 ' xs="12" lg="4">
                                    <Row style={colBorderStyle} className='p-4 '>
                                        <Col xs="12" sm="12">
                                            <center><FontAwesomeIcon style={iconStyle} icon={faEnvelope} /></center>
                                        </Col>
                                        <Col style={titleStyle}  xs="12" sm="12">
                                            <center>Email Us</center>
                                        </Col>
                                        <Col style={infoStyle} xs="12" sm="12">
                                            <center>{global.config.site.company.email}</center>
                                        </Col>
                                        <Col xs="12" sm="12">&nbsp;</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        
                    </Row>
                
            </Container>
        </Row>
    );
};