
import { Container, Button, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../config';

export const Hero = () => {
    
    const containerStyle = {
        backgroundColor: global.config.site.hero_background_color,
        backgroundBlendMode: 'overlay',
        backgroundImage: global.config.site.hero_background_image,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    }

    const buttonStyle = {
        fontSize: '20px',
    };

    const h1Style = {
        fontSize: global.config.site.hero_title_font_size,
        fontWeight: global.config.site.hero_title_font_weight,
        color: global.config.site.hero_title_font_color,
    }

    const pStyle = {
        fontSize: global.config.site.hero_description_font_size,
        fontWeight: global.config.site.hero_description_font_weight,
        color: global.config.site.hero_description_font_color,
    }

    return (
        <Row style={containerStyle} className='g-0'>
        <Container className="g-0 col-12 col-xxl-8 px-4 py-5" fluid>
            <div className='row flex-lg-row align-items-center g-5 py-5'>
                <div className='col-12 col-sm-12 col-lg-6'>
                    <Image width="100%" src={global.config.site.hero_title_image} />
                </div>
                <div className='col-12 col-sm-12 col-lg-6'>
                    <h1 style={h1Style}>{global.config.site.hero_title_text}</h1>
                    
                    <p style={pStyle}>{global.config.site.hero_description_text}</p>
                    <div className='justify-content-md-sart d-md-flex d-grid gap-2'>
                        <Link to="/shop">
                            <Button className="pr-5 pl-5" style={buttonStyle} variant="outline-primary">{global.config.site.hero_button_text}</Button>{' '}
                        </Link>
                    </div>
                    

                </div>
                
            </div>
        </Container>
        </Row>
    );
};
