import { Row, Col, Container } from 'react-bootstrap';
import '../config';

const OrderShipping = () => {
   
    const pStyle = {
        color: global.config.site.order_shipping_p_font_color,
        fontSize: global.config.site.order_shipping_p_font_size,
        fontWeight: global.config.site.order_shipping_p_font_weight,
    };

    const rowStyle = {
        padding: global.config.site.order_shipping_inner_container_padding,
        border: global.config.site.order_shipping_inner_container_border,
        borderRadius: global.config.site.order_shipping_inner_container_border_radius,
        color: global.config.site.order_shipping_inner_container_text_color,
        backgroundColor: global.config.site.order_shipping_inner_container_background_color,
        backgroundImage: global.config.site.order_shipping_inner_container_background_image,
    }

    const outerRowStyle = {
        padding: global.config.site.order_shipping_outer_container_padding,
    }

    const h1Style = {
        color: global.config.site.order_shipping_h1_font_color,
        fontSize: global.config.site.order_shipping_h1_font_size,
        fontWeight: global.config.site.order_shipping_h1_font_weight,
    }

    return (
        <Row className='g-0' style={outerRowStyle}>
        <Container className={global.config.site.inner_container_size}>
                <Row style={rowStyle}>
                    <Col xs="12" sm="12">
                        <center>
                            <h1 style={h1Style}>Ordering & Shipping</h1>
                        </center>
                    </Col>
                    <Col xs="12">
                        
                        <p className='pt-3' style={pStyle}>
                        By placing an order, you will be charged the full price of the package you selected, and the order will be shipped to the address you provided during checkout. All items are processed and shipped within 3 business days of purchaes and are shipped Priority mail via USPS. Please allow 3-5 business days to receive your product(s).
                        </p>
                    </Col>
                    
                </Row>
        
        </Container>
        </Row>
    );
};

export default OrderShipping;