
module.exports = global.config = {
    api: {
        products_url: process.env.REACT_APP_API_PRODUCTS_URL,
        product_url: process.env.REACT_APP_API_PRODUCT_URL,
        send_order_url: process.env.REACT_APP_API_SEND_ORDER_URL,
        site_id: process.env.REACT_APP_API_SITE_ID,
        site_config_file: process.env.REACT_APP_SITE_CONFIG_FILE,
        site_use_config_file: process.env.REACT_APP_SITE_USE_CONFIG_FILE,
        site_use_session_config: process.env.REACT_APP_SITE_USE_SESSION_CONFIG,
        site_session_config_expiration: process.env.REACT_APP_SITE_SITE_SESSION_CONFIG_EXPIRATION,
    },
    shop: {
        items_per_page: process.env.REACT_APP_SHOP_ITEMS_PER_PAGE,
    },
    terms:{
        company: process.env.REACT_APP_TERMS_COMPANY,
        email: process.env.REACT_APP_TERMS_EMAIL,
        phone: process.env.REACT_APP_TERMS_PHONE,
        address1: process.env.REACT_APP_TERMS_ADDRESS1,
        address2: process.env.REACT_APP_TERMS_ADDRESS2,
        website: process.env.REACT_APP_TERMS_WEBSITE,
        return_address: process.env.REACT_APP_TERMS_RETURN_ADDRESS,
        return_address2: process.env.REACT_APP_TERMS_RETURN_ADDRESS2,
    },
    site: {
        // include name to show name, include logo to show logo...
        site_title: process.env.REACT_APP_SITE_TITLE,
        site_meta: process.env.REACT_APP_SITE_META,
        site_description: process.env.REACT_APP_SITE_META_DESCRIPTION,
        nav_title: process.env.REACT_APP_SITE_NAV_TITLE,
        nav_logo: process.env.REACT_APP_SITE_NAV_LOGO,
        discover_use: process.env.REACT_APP_SITE_DISCOVER_USE,
        discover_button_text: process.env.REACT_APP_SITE_DISCOVER_BUTTON_TEXT,
        discover_logo: process.env.REACT_APP_SITE_DISCOVER_LOGO,

        discover_use_type: process.env.REACT_APP_SITE_DISCOVER_USE_TYPE,
        discover_image_border_radius: process.env.REACT_APP_SITE_DISCOVER_IMAGE_BORDER_RADIUS,
        discover_image_box_shadow: process.env.REACT_APP_SITE_DISCOVER_IMAGE_BOX_SHADOW,
        discover_container_style_background_color: process.env.REACT_APP_SITE_DISCOVER_CONTAINER_STYLE_BACKGROUND_COLOR,
        discover_container_style_box_shadow: process.env.REACT_APP_SITE_DISCOVER_CONTAINER_STYLE_BOX_SHADOW, 
        discover_container_style_position: process.env.REACT_APP_SITE_DISCOVER_CONTAINER_STYLE_POSITION, 
        discover_row_main_style_margin_top: process.env.REACT_APP_SITE_DISCOVER_ROW_MAIN_STYLE_MARGIN_TOP, 

        discover_title: process.env.REACT_APP_SITE_DISCOVER_TITLE,
        discover_text: process.env.REACT_APP_SITE_DISCOVER_TEXT,
        discover_text_font_size: process.env.REACT_APP_SITE_DISCOVER_TEXT_FONT_SIZE,
        discover_text_font_weight: process.env.REACT_APP_SITE_DISCOVER_TEXT_FONT_WEIGHT,
        discover_title_font_size: process.env.REACT_APP_SITE_DISCOVER_TITLE_FONT_SIZE,
        discover_title_font_weight: process.env.REACT_APP_SITE_DISCOVER_TITLE_FONT_WEIGHT,
        discover_button_font_size: process.env.REACT_APP_SITE_DISCOVER_BUTTON_FONT_SIZE,
        discover_button_font_weight: process.env.REACT_APP_SITE_DISCOVER_BUTTON_FONT_WEIGHT,
        discover_image_width: process.env.REACT_APP_SITE_DISCOVER_IMAGE_WIDTH,
        discover_border: process.env.REACT_APP_SITE_DISCOVER_BORDER,
        discover_border_radius: process.env.REACT_APP_SITE_DISCOVER_BORDER_RADIUS,
        discover_padding: process.env.REACT_APP_SITE_DISCOVER_PADDING, 
        company: {
            name: process.env.REACT_APP_SITE_COMPANY_NAME,
            address: process.env.REACT_APP_SITE_COMPANY_ADDRESS,
            return_address: process.env.REACT_APP_SITE_COMPANY_RETURN_ADDRESS,
            customer_support_number: process.env.REACT_APP_SITE_COMPANY_CUSTOMER_SUPPORT_NUMBER,
            email: process.env.REACT_APP_SITE_COMPANY_EMAIL,
            website: process.env.REACT_APP_SITE_COMPANY_WEBSITE
        },
        // general
        font_family: process.env.REACT_APP_SITE_FONT_FAMILY,
        font_size: process.env.REACT_APP_SITE_FONT_SIZE,
        background_color: process.env.REACT_APP_SITE_BACKGROUND_COLOR,
        background_image: process.env.REACT_APP_SITE_BACKGROUND_IMAGE,
        background_repeat: process.env.REACT_APP_SITE_BACKGROUND_REPEAT,
        inner_container_size: process.env.REACT_APP_SITE_INNER_CONTAINER_SIZE,
        // header offer
        header_offer_use: process.env.REACT_APP_SITE_HEADER_OFFER_USE,
        header_offer_background_color: process.env.REACT_APP_SITE_HEADER_OFFER_BACKGROUND_COLOR,
        header_offer_text_color: process.env.REACT_APP_SITE_HEADER_OFFER_TEXT_COLOR,
        header_offer_font_size: process.env.REACT_APP_SITE_HEADER_OFFER_FONT_SIZE,
        header_offer_text: process.env.REACT_APP_SITE_HEADER_OFFER_TEXT,
        input_background_color: process.env.REACT_APP_SITE_INPUT_BACKGROUND_COLOR,
        // header
        header: process.env.REACT_APP_SITE_HEADER,
        header_background_image: process.env.REACT_APP_SITE_HEADER_BACKGROUND_IMAGE,
        header_background_color: process.env.REACT_APP_SITE_HEADER_BACKGROUND_COLOR,
        header_logo_spacing: process.env.REACT_APP_SITE_HEADER_LOGO_SPACING,
        header_logo_height: process.env.REACT_APP_SITE_HEADER_LOGO_HEIGHT,
        // footer
        footer: process.env.REACT_APP_SITE_FOOTER,
        footer_logo_height: process.env.REACT_APP_SITE_FOOTER_LOGO_HEIGHT,
        footer_nav_link_font_size: process.env.REACT_APP_SITE_FOOTER_NAV_LINK_FONT_SIZE,
        footer_nav_link_font_color: process.env.REACT_APP_SITE_FOOTER_NAV_LINK_FONT_COLOR,
        footer_nav_link_font_weight: process.env.REACT_APP_SITE_FOOTER_NAV_LINK_FONT_WEIGHT,
        footer_cards_logo_height: process.env.REACT_APP_SITE_FOOTER_CARDS_LOGO_HEIGHT,
        footer_nav_copyright_font_size: process.env.REACT_APP_SITE_FOOTER_NAV_COPYRIGHT_FONT_SIZE,
        footer_nav_copyright_font_color: process.env.REACT_APP_SITE_FOOTER_NAV_COPYRIGHT_FONT_COLOR,
        footer_nav_copyright_font_weight: process.env.REACT_APP_SITE_FOOTER_NAV_COPYRIGHT_FONT_WEIGHT,
        footer_container_background_color: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_COLOR,
        footer_container_background_image: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_IMAGE,
        footer_container_background_blend_mode: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_BLEND_MODE,
        footer_container_padding: process.env.REACT_APP_SITE_FOOTER_CONTAINER_PADDING,
        footer_container_font_color: process.env.REACT_APP_SITE_FOOTER_CONTAINER_FONT_COLOR,
        footer_container_font_size: process.env.REACT_APP_SITE_FOOTER_CONTAINER_FONT_SIZE,
        footer_container_background_size: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_SIZE,
        footer_container_background_position: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_POSITION,
        footer_container_background_repeat: process.env.REACT_APP_SITE_FOOTER_CONTAINER_BACKGROUND_REPEAT,
        footer_container_font_weight: process.env.REACT_APP_SITE_FOOTER_CONTAINER_FONT_WEIGHT,
        footer_container_position: process.env.REACT_APP_SITE_FOOTER_CONTAINER_POSITION,
        footer_container_height: process.env.REACT_APP_SITE_FOOTER_CONTAINER_HEIGHT,
        footer_container_margin: process.env.REACT_APP_SITE_FOOTER_CONTAINER_MARGIN,
        footer_container_2_background_color: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_COLOR,
        footer_container_2_background_image: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_IMAGE,
        footer_container_2_background_blend_mode: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_BLEND_MODE,
        footer_container_2_padding: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_PADDING,
        footer_container_2_font_color: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_FONT_COLOR,
        footer_container_2_font_size: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_FONT_SIZE,
        footer_container_2_background_size: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_SIZE,
        footer_container_2_background_position: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_POSITION,
        footer_container_2_background_repeat: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_BACKGROUND_REPEAT,
        footer_container_2_font_weight: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_FONT_WEIGHT,
        footer_container_2_position: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_POSITION,
        footer_container_2_height: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_HEIGHT,
        footer_container_2_margin: process.env.REACT_APP_SITE_FOOTER_CONTAINER_2_MARGIN,
        footer_contact_text: process.env.REACT_APP_SITE_FOOTER_CONTACT_TEXT,
        footer_links_text: process.env.REACT_APP_SITE_FOOTER_LINKS_TEXT,
        footer_nav_link_padding: process.env.REACT_APP_SITE_FOOTER_NAV_LINK_PADDING,
        footer_header_font_size:process.env.REACT_APP_SITE_FOOTER_HEADER_FONT_SIZE,
        footer_header_font_weight:process.env.REACT_APP_SITE_FOOTER_HEADER_FONT_WEIGHT,
        footer_header_font_color:process.env.REACT_APP_SITE_FOOTER_HEADER_FONT_COLOR,
        // pages header
        
        pages_header_background_image: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_IMAGE,
        pages_header_background_color: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_COLOR,
        pages_header_background_blend_mode: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_BLEND_MODE,
        pages_header_background_size: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_SIZE,
        pages_header_background_position: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_POSITION,
        pages_header_background_repeat: process.env.REACT_APP_SITE_PAGES_HEADER_BACKGROUND_REPEAT,
        pages_header_position: process.env.REACT_APP_SITE_PAGES_HEADER_POSITION,
        pages_header_height: process.env.REACT_APP_SITE_PAGES_HEADER_HEIGHT,
        pages_header_margin: process.env.REACT_APP_SITE_PAGES_HEADER_MARGIN,
        pages_header_header_padding: process.env.REACT_APP_SITE_PAGES_HEADER_PADDING,
        pages_header_font_color: process.env.REACT_APP_SITE_PAGES_HEADER_FONT_COLOR,
        pages_header_font_size: process.env.REACT_APP_SITE_PAGES_HEADER_FONT_SIZE,
        pages_header_font_weight: process.env.REACT_APP_SITE_PAGES_HEADER_FONT_WEIGHT,
        
        // nav link
        nav_link_text_font_size: process.env.REACT_APP_SITE_NAV_LINK_TEXT_FONT_SIZE,
        nav_link_text_font_weight: process.env.REACT_APP_SITE_NAV_LINK_TEXT_FONT_WEIGHT,
        nav_link_text_font_family: process.env.REACT_APP_SITE_NAV_LINK_TEXT_FONT_FAMILY,
        nav_link_text_color: process.env.REACT_APP_SITE_NAV_LINK_TEXT_COLOR,
        nav_link_text_active_color: process.env.REACT_APP_SITE_NAV_LINK_TEXT_ACTIVE_COLOR,
        nav_link_text_hover_color: process.env.REACT_APP_SITE_NAV_LINK_TEXT_HOVER_COLOR,

        // footer link
        footer_link_text_font_size: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_FONT_SIZE,
        footer_link_text_font_weight: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_FONT_WEIGHT,
        footer_link_text_font_family: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_FONT_FAMILY,
        footer_link_text_color: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_COLOR,
        footer_link_text_active_color: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_ACTIVE_COLOR,
        footer_link_text_hover_color: process.env.REACT_APP_SITE_FOOTER_LINK_TEXT_HOVER_COLOR,
        // button
        button_text_color: process.env.REACT_APP_SITE_BUTTON_TEXT_COLOR,
        button_background_color: process.env.REACT_APP_SITE_BUTTON_BACKGROUND_COLOR,
        button_border: process.env.REACT_APP_SITE_BUTTON_BORDER,
        button_border_radius: process.env.REACT_APP_SITE_BUTTON_BORDER_RADIUS,
        button_text_hover_color: process.env.REACT_APP_SITE_BUTTON_TEXT_HOVER_COLOR,
        button_hover_background_color: process.env.REACT_APP_SITE_BUTTON_HOVER_BACKGROUND_COLOR,
        button_hover_border: process.env.REACT_APP_SITE_BUTTON_HOVER_BORDER,
        button_hover_border_radius: process.env.REACT_APP_SITE_BUTTON_HOVER_BORDER_RADIUS,
        // hero
        hero_section_use: process.env.REACT_APP_SITE_HERO_SECTION_USE,
        hero_type: process.env.REACT_APP_SITE_HERO_TYPE,
        hero_background_color: process.env.REACT_APP_SITE_HERO_BACKGROUND_COLOR,
        hero_background_image: process.env.REACT_APP_SITE_HERO_BACKGROUND_IMAGE,
        hero_title_font_size: process.env.REACT_APP_SITE_HERO_TITLE_FONT_SIZE,
        hero_title_font_weight: process.env.REACT_APP_SITE_HERO_TITLE_FONT_WEIGHT,
        hero_description_font_size: process.env.REACT_APP_SITE_HERO_DESCRIPTION_FONT_SIZE,
        hero_description_font_weight: process.env.REACT_APP_SITE_HERO_DESCRIPTION_FONT_WEIGHT,
        hero_title_text: process.env.REACT_APP_SITE_HERO_TITLE_TEXT,
        hero_description_text: process.env.REACT_APP_SITE_HERO_DESCRIPTION_TEXT,
        hero_button_text: process.env.REACT_APP_SITE_HERO_BUTTON_TEXT,
        hero_description_font_color: process.env.REACT_APP_SITE_HERO_DESCRIPTION_FONT_COLOR,
        hero_title_font_color: process.env.REACT_APP_SITE_HERO_TITLE_FONT_COLOR,
        hero_title_image: process.env.REACT_APP_SITE_HERO_TITLE_IMAGE,
        hero_container_padding: process.env.REACT_APP_SITE_HERO_CONTAINER_PADDING,
        // best sellers
        best_seller_use: process.env.REACT_APP_SITE_BEST_SELLER_USE,
        best_seller_display: process.env.REACT_APP_SITE_BEST_SELLER_DISPLAY,
        best_seller_border: process.env.REACT_APP_SITE_BEST_SELLER_BORDER,
        best_seller_padding: process.env.REACT_APP_SITE_BEST_SELLER_PADDING,
        best_seller_header_text: process.env.REACT_APP_SITE_BEST_SELLER_HEADER_TEXT,
        best_seller_title_background_color: process.env.REACT_APP_SITE_BEST_SELLER_TITLE_BACKGROUND_COLOR,
        best_seller_border_radius: process.env.REACT_APP_SITE_BEST_SELLER_BORDER_RADIUS,
        best_seller_product_title_font_color:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_TITLE_FONT_COLOR,
        best_seller_product_title_font_size:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_TITLE_FONT_SIZE,
        best_seller_product_title_font_weight:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_TITLE_FONT_WEIGHT,
        best_seller_product_price_font_color:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_PRICE_FONT_COLOR,
        best_seller_product_price_font_size:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_PRICE_FONT_SIZE,
        best_seller_product_price_font_weight:process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_PRICE_FONT_WEIGHT,
        best_seller_header_background_color:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_BACKGROUND_COLOR,
        best_seller_header_background_image:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_BACKGROUND_IMAGE,
        best_seller_header_padding:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_PADDING,
        best_seller_header_font_color:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_FONT_COLOR,
        best_seller_header_font_size:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_FONT_SIZE,
        best_seller_header_font_weight:process.env.REACT_APP_SITE_BEST_SELLER_HEADER_FONT_WEIGHT,
        best_seller_product_image_radius: process.env.REACT_APP_SITE_BEST_SELLER_PRODUCT_IMAGE_RADIUS,
        best_seller_container_padding: process.env.REACT_APP_SITE_BEST_SELLER_CONTAINER_PADDING,
        best_seller_container_background_color: process.env.REACT_APP_SITE_BEST_SELLER_CONTAINER_BACKGROUND_COLOR,
        best_seller_more_button_text: process.env.REACT_APP_SITE_BEST_SELLER_MORE_BUTTON_TEXT,
        // order shipping
        order_shipping_show_home: process.env.REACT_APP_SITE_ORDER_SHIPPNG_SHOW_HOME,
        order_shipping_p_font_size: process.env.REACT_APP_SITE_ORDER_SHIPPING_P_FONT_SIZE,
        order_shipping_p_font_color: process.env.REACT_APP_SITE_ORDER_SHIPPING_P_FONT_COLOR,
        order_shipping_p_font_weight: process.env.REACT_APP_SITE_ORDER_SHIPPING_P_FONT_WEIGHT,
        order_shipping_inner_container_padding: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_PADDING,
        order_shipping_inner_container_border: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_BORDER,
        order_shipping_inner_container_border_radius: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_BORDER_RADIUS,
        order_shipping_inner_container_text_color: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_TEXT_COLOR,
        order_shipping_inner_container_background_color: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_BACKGROUND_COLOR,
        order_shipping_inner_container_background_image: process.env.REACT_APP_SITE_ORDER_SHIPPING_INNER_CONTAINER_BACKGROUND_IMAGE,
        order_shipping_outer_container_padding: process.env.REACT_APP_SITE_ORDER_SHIPPING_OUTER_CONTAINER_PADDING,
        order_shipping_h1_font_size:process.env.REACT_APP_SITE_ORDER_SHIPPING_H1_FONT_SIZE,
        order_shipping_h1_font_color:process.env.REACT_APP_SITE_ORDER_SHIPPING_H1_FONT_COLOR,
        order_shipping_h1_font_weight:process.env.REACT_APP_SITE_ORDER_SHIPPING_H1_FONT_WEIGHT,
        // contact us
        contact_us_show_home: process.env.REACT_APP_SITE_CONTACT_US_SHOW_HOME,
        contact_us_type: process.env.REACT_APP_SITE_CONTACT_US_TYPE,
        contact_us_header_text: process.env.REACT_APP_SITE_CONTACT_US_HEADER_TEXT,
        contact_us_header_background_image: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_IMAGE,
        contact_us_header_background_color: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_COLOR,
        contact_us_header_background_blend_mode: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_BLEND_MODE,
        contact_us_header_background_size: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_SIZE,
        contact_us_header_background_position: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_POSITION,
        contact_us_header_background_repeat: process.env.REACT_APP_SITE_CONTACT_US_HEADER_BACKGROUND_REPEAT,
        contact_us_header_position: process.env.REACT_APP_SITE_CONTACT_US_HEADER_POSITION,
        contact_us_header_height: process.env.REACT_APP_SITE_CONTACT_US_HEADER_HEIGHT,
        contact_us_header_margin: process.env.REACT_APP_SITE_CONTACT_US_HEADER_MARGIN,
        contact_us_header_header_padding: process.env.REACT_APP_SITE_CONTACT_US_HEADER_PADDING,
        contact_us_header_font_color: process.env.REACT_APP_SITE_CONTACT_US_HEADER_FONT_COLOR,
        contact_us_header_font_size: process.env.REACT_APP_SITE_CONTACT_US_HEADER_FONT_SIZE,
        contact_us_header_font_weight: process.env.REACT_APP_SITE_CONTACT_US_HEADER_FONT_WEIGHT,
        contact_us_container_background_color: process.env.REACT_APP_SITE_CONTACT_US_CONTAINER_BACKGROUND_COLOR,
        contact_us_container_background_image: process.env.REACT_APP_SITE_CONTACT_US_CONTAINER_BACKGROUND_IMAGE,
        contact_us_container_padding: process.env.REACT_APP_SITE_CONTACT_US_CONTAINER_PADDING,
        contact_us_content_box_border: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_BOX_BORDER,
        contact_us_content_box_border_radius: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_BOX_BORDER_RADIUS,
        contact_us_content_box_padding: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_BOX_PADDING,
        contact_us_content_box_shadow: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_BOX_SHADOW,
        contact_us_inner_container_border: process.env.REACT_APP_SITE_CONTACT_US_INNER_CONTAINER_BORDER,
        contact_us_inner_container_border_radius: process.env.REACT_APP_SITE_CONTACT_US_INNER_CONTAINER_BORDER_RADIUS,
        contact_us_content_title_font_size: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_TITLE_FONT_SIZE,
        contact_us_content_title_font_weight: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_TITLE_FONT_WEIGHT,
        contact_us_content_title_font_color: process.env.REACT_APP_SITE_CONTACT_US_CONTENT_TITLE_FONT_COLOR,
        contact_us_icon_color: process.env.REACT_APP_SITE_CONTACT_US_ICON_COLOR,
        contact_us_icon_height: process.env.REACT_APP_SITE_CONTACT_US_ICON_HEIGHT,
        contact_us_content_info_font_size: process.env.REACT_APP_SITE_CONTACT_US_INFO_FONT_SIZE,
        contact_us_content_info_font_weight: process.env.REACT_APP_SITE_CONTACT_US_INFO_FONT_WEIGHT,
        contact_us_content_info_font_color: process.env.REACT_APP_SITE_CONTACT_US_INFO_FONT_COLOR,
        contact_us_heading_padding: process.env.REACT_APP_SITE_CONTACT_US_HEADING_PADDING,
        //cart
        cart_show_home: process.env.REACT_APP_SITE_CART_SHOW_HOME,
        cart_type: process.env.REACT_APP_SITE_CART_TYPE,
        cart_header_text: process.env.REACT_APP_SITE_CART_HEADER_TEXT,
        cart_header_background_image: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_IMAGE,
        cart_header_background_color: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_COLOR,
        cart_header_background_blend_mode: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_BLEND_MODE,
        cart_header_background_size: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_SIZE,
        cart_header_background_position: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_POSITION,
        cart_header_background_repeat: process.env.REACT_APP_SITE_CART_HEADER_BACKGROUND_REPEAT,
        cart_header_position: process.env.REACT_APP_SITE_CART_HEADER_POSITION,
        cart_header_height: process.env.REACT_APP_SITE_CART_HEADER_HEIGHT,
        cart_header_margin: process.env.REACT_APP_SITE_CART_HEADER_MARGIN,
        cart_header_header_padding: process.env.REACT_APP_SITE_CART_HEADER_PADDING,
        cart_header_font_color: process.env.REACT_APP_SITE_CART_HEADER_FONT_COLOR,
        cart_header_font_size: process.env.REACT_APP_SITE_CART_HEADER_FONT_SIZE,
        cart_header_font_weight: process.env.REACT_APP_SITE_CART_HEADER_FONT_WEIGHT,
        page_container_background_color: process.env.REACT_APP_SITE_PAGE_CONTAINER_BACKGROUND_COLOR,
        page_container_background_image: process.env.REACT_APP_SITE_PAGE_CONTAINER_BACKGROUND_IMAGE,
        page_container_padding: process.env.REACT_APP_SITE_PAGE_CONTAINER_PADDING,
        cart_content_box_border: process.env.REACT_APP_SITE_CART_CONTENT_BOX_BORDER,
        cart_content_box_border_radius: process.env.REACT_APP_SITE_CART_CONTENT_BOX_BORDER_RADIUS,
        cart_content_box_padding: process.env.REACT_APP_SITE_CART_CONTENT_BOX_PADDING,
        cart_content_box_shadow: process.env.REACT_APP_SITE_CART_CONTENT_BOX_SHADOW,
        cart_inner_container_border: process.env.REACT_APP_SITE_CART_INNER_CONTAINER_BORDER,
        cart_inner_container_border_radius: process.env.REACT_APP_SITE_CART_INNER_CONTAINER_BORDER_RADIUS,
        cart_inner_container_padding: process.env.REACT_APP_SITE_CART_INNER_CONTAINER_PADDING,
        cart_inner_container_background_color: process.env.REACT_APP_SITE_CART_INNER_CONTAINER_BACKGROUND_COLOR,
        cart_content_title_font_size: process.env.REACT_APP_SITE_CART_CONTENT_TITLE_FONT_SIZE,
        cart_content_title_font_weight: process.env.REACT_APP_SITE_CART_CONTENT_TITLE_FONT_WEIGHT,
        cart_content_title_font_color: process.env.REACT_APP_SITE_CART_CONTENT_TITLE_FONT_COLOR,
        cart_icon_color: process.env.REACT_APP_SITE_CART_ICON_COLOR,
        cart_icon_height: process.env.REACT_APP_SITE_CART_ICON_HEIGHT,
        cart_content_info_font_size: process.env.REACT_APP_SITE_CART_INFO_FONT_SIZE,
        cart_content_info_font_weight: process.env.REACT_APP_SITE_CART_INFO_FONT_WEIGHT,
        cart_content_info_font_color: process.env.REACT_APP_SITE_CART_INFO_FONT_COLOR,
        page_heading_padding: process.env.REACT_APP_SITE_PAGE_HEADING_PADDING,
        cart_return_button_text: process.env.REACT_APP_SITE_CART_RETURN_BUTTON_TEXT,
        
        cart_table_total_style_border: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_STYLE_BORDER,
        cart_table_total_style_border_radius: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_STYLE_BORDER_RADIUS,
        cart_table_total_style_width: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_STYLE_WIDTH,
        cart_table_total_style_font_size: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_STYLE_FONT_SIZE,
        cart_table_total_style_font_weight: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_STYLE_FONT_WEIGHT,
        table_cart_total_style_font_color: process.env.REACT_APP_SITE_TABLE_CART_TOTAL_STYLE_FONT_COLOR,
        cart_table_total_tr_style_border: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_TR_STYLE_BORDER,
        cart_table_total_tr_style_font_size: process.env.REACT_APP_SITE_CART_TABLE_TOTAL_TR_STYLE_FONT_SIZE,
        table_cart_total_tr_style_font_color: process.env.REACT_APP_SITE_TABLE_CART_TOTAL_TR_STYLE_FONT_COLOR,
        cart_p_font_size: process.env.REACT_APP_SITE_CART_P_FONT_SIZE,
        cart_p_width: process.env.REACT_APP_SITE_CART_P_WIDTH,
        cart_empty_cart_style_background_color: process.env.REACT_APP_SITE_CART_EMPTY_CART_STYLE_BACKGROUND_COLOR,
        cart_empty_cart_style_color: process.env.REACT_APP_SITE_CART_EMPTY_CART_STYLE_COLOR,
        cart_empty_cart_style_font_size: process.env.REACT_APP_SITE_CART_EMPTY_CART_STYLE_FONT_SIZE,
        cart_empty_cart_style_font_weight: process.env.REACT_APP_SITE_CART_EMPTY_CART_STYLE_FONT_WEIGHT,
        cart_empty_cart_style_padding: process.env.REACT_APP_SITE_CART_EMPTY_CART_STYLE_PADDING,
        cart_table_head_style_font_size: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_FONT_SIZE,
        cart_table_head_style_font_weight: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_FONT_WEIGHT,
        cart_table_head_style_font_color: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_FONT_COLOR,
        cart_table_head_style_background_color: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_BACKGROUND_COLOR,
        cart_table_head_style_border_radius: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_BORDER_RADIUS,
        cart_table_head_style_padding: process.env.REACT_APP_SITE_CART_TABLE_HEAD_STYLE_PADDING,
        
        cart_table_style_width: process.env.REACT_APP_SITE_CART_TABLE_STYLE_WIDTH,
        cart_table_style_font_size: process.env.REACT_APP_SITE_CART_TABLE_STYLE_FONT_SIZE,
        cart_table_style_font_weight: process.env.REACT_APP_SITE_CART_TABLE_STYLE_FONT_WEIGHT,
        cart_table_style_font_color: process.env.REACT_APP_SITE_CART_TABLE_STYLE_FONT_COLOR,
        cart_table_style_border: process.env.REACT_APP_SITE_CART_TABLE_STYLE_BORDER,
        cart_sub_total_price_font_color: process.env.REACT_APP_SITE_CART_SUB_TOTAL_PRICE_FONT_COLOR,
        cart_sub_total_price_font_size: process.env.REACT_APP_SITE_CART_SUB_TOTAL_PRICE_FONT_SIZE,
        cart_sub_total_price_font_weight: process.env.REACT_APP_SITE_CART_SUB_TOTAL_PRICE_FONT_WEIGHT,
        cart_sub_total_total_price_font_color: process.env.REACT_APP_SITE_CART_SUB_TOTAL_TOTAL_PRICE_FONT_COLOR,
        cart_sub_total_total_price_font_size: process.env.REACT_APP_SITE_CART_SUB_TOTAL_TOTAL_PRICE_FONT_SIZE,
        cart_sub_total_total_price_font_weight: process.env.REACT_APP_SITE_CART_SUB_TOTAL_TOTAL_PRICE_FONT_WEIGHT,
        cart_cart_totals_style_font_size: process.env.REACT_APP_SITE_CART_CART_TOTALS_STYLE_FONT_SIZE,
        cart_cart_totals_style_font_color: process.env.REACT_APP_SITE_CART_CART_TOTALS_STYLE_FONT_COLOR,

        // checkout
        checkout_header_text: process.env.REACT_APP_SITE_CHECKOUT_HEADER_TEXT,
        checkout_data_paragraph_style_font_size: process.env.REACT_APP_SITE_CHECKOUT_DATA_PARAGRAPH_STYLE_FONT_SIZE,
        checkout_data_paragraph_style_font_weight: process.env.REACT_APP_SITE_CHECKOUT_DATA_PARAGRAPH_STYLE_FONT_WEIGHT,
        checkout_data_paragraph_style_font_color: process.env.REACT_APP_SITE_CHECKOUT_DATA_PARAGRAPH_STYLE_FONT_COLOR,
        checkout_info_header_style_font_size: process.env.REACT_APP_SITE_CHECKOUT_INFO_HEADER_STYLE_FONT_SIZE,
        checkout_info_header_style_font_weight: process.env.REACT_APP_SITE_CHECKOUT_INFO_HEADER_STYLE_FONT_WEIGHT,
        checkout_info_header_style_font_color: process.env.REACT_APP_SITE_CHECKOUT_INFO_HEADER_STYLE_FONT_COLOR,
        checkout_credit_card_container_style_padding: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_CONTAINER_STYLE_PADDING,
        checkout_credit_card_container_style_border: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_CONTAINER_STYLE_BORDER,
        checkout_credit_card_container_style_background_color: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_CONTAINER_STYLE_BACKGROUND_COLOR,
        checkout_credit_card_container_style_border_radius: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_CONTAINER_STYLE_BORDER_RADIUS,
        checkout_div_style_border: process.env.REACT_APP_SITE_CHECKOUT_DIV_STYLE_BORDER,
        checkout_div_style_border_radius: process.env.REACT_APP_SITE_CHECKOUT_DIV_STYLE_BORDER_RADIUS,
        checkout_span_required_font_color: process.env.REACT_APP_SITE_CHECKOUT_SPAN_REQUIRED_FONT_COLOR,
        checkout_p_style_font_size: process.env.REACT_APP_SITE_CHECKOUT_P_STYLE_FONT_SIZE,
        checkout_p_style_width: process.env.REACT_APP_SITE_CHECKOUT_P_STYLE_WIDTH,
        checkout_section_style_background_color: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_BACKGROUND_COLOR,
        checkout_section_style_font_color: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_FONT_COLOR,
        checkout_section_style_border: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_BORDER,
        checkout_section_style_border_radius: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_BORDER_RADIUS,
        checkout_section_style_padding: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_PADDING,
        checkout_section_style_height: process.env.REACT_APP_SITE_CHECKOUT_SECTION_STYLE_HEIGHT,
        checkout_order_table_style_font_size: process.env.REACT_APP_SITE_CHECKOUT_ORDER_TABLE_STYLE_FONT_SIZE,
        checkout_order_table_style_font_weight: process.env.REACT_APP_SITE_CHECKOUT_ORDER_TABLE_STYLE_FONT_WEIGHT,
        checkout_order_table_style_font_color: process.env.REACT_APP_SITE_CHECKOUT_ORDER_TABLE_STYLE_FONT_COLOR,
        checkout_credit_card_fields_style_background_color: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_FIELDS_STYLE_BACKGROUND_COLOR,
        checkout_credit_card_fields_style_padding: process.env.REACT_APP_SITE_CHECKOUT_CREDIT_CARD_FIELDS_STYLE_PADDING,
        
        // table spacing
        table_cell_padding: process.env.REACT_APP_SITE_TABLE_CELL_PADDING,
        table_cell_spacing: process.env.REACT_APP_SITE_TABLE_CELL_SPACING,
        // thank you
        thank_you_header_text: process.env.REACT_APP_SITE_THANK_YOU_HEADER_TEXT,
        thank_you_header_description: process.env.REACT_APP_SITE_THANK_YOU_HEADER_DESCRIPTION,

        // shop
        shop_header_text: process.env.REACT_APP_SITE_SHOP_HEADER_TEXT,
        shop_showing_style_font_size: process.env.REACT_APP_SITE_SHOP_SHOWING_STYLE_FONT_SIZE,
        shop_showing_style_font_weight: process.env.REACT_APP_SITE_SHOP_SHOWING_STYLE_FONT_WEIGHT,
        shop_showing_style_font_color: process.env.REACT_APP_SITE_SHOP_SHOWING_STYLE_FONT_COLOR,
        // product details
        product_product_detail_style_padding: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_STYLE_PADDING,
        product_product_detail_style_border: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_STYLE_BORDER,
        product_product_detail_style_border_color: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_STYLE_BORDER_COLOR,
        product_product_detail_style_border_radius: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_STYLE_BORDER_RADIUS,
        product_product_image_style_border_radius: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_IMAGE_STYLE_BORDER_RADIUS,
        product_p_style_font_size: process.env.REACT_APP_SITE_PRODUCT_P_STYLE_FONT_SIZE,
        product_toast_style_width: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_WIDTH,
        product_toast_style_font_size: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_FONT_SIZE,
        product_toast_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_FONT_WEIGHT,
        product_toast_style_font_color: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_FONT_COLOR,
        product_toast_style_background_color: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_BACKGROUND_COLOR,
        product_toast_style_border: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_BORDER,
        product_toast_style_border_radius: process.env.REACT_APP_SITE_PRODUCT_TOAST_STYLE_BORDER_RADIUS,
        product_view_cart_button_font_color: process.env.REACT_APP_SITE_PRODUCT_VIEW_CART_BUTTON_FONT_COLOR,
        product_view_cart_button_border: process.env.REACT_APP_SITE_PRODUCT_VIEW_CART_BUTTON_BORDER,
        product_view_cart_button_border_radius: process.env.REACT_APP_SITE_PRODUCT_VIEW_CART_BUTTON_BORDER_RADIUS,
        product_view_cart_button_background_color: process.env.REACT_APP_SITE_PRODUCT_VIEW_CART_BUTTON_BACKGROUND_COLOR,
        product_view_cart_button_float: process.env.REACT_APP_SITE_PRODUCT_VIEW_CART_BUTTON_FLOAT,
        product_bread_crumb_style_font_size: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_FONT_SIZE,
        product_bread_crumb_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_FONT_SIZE,
        product_bread_crumb_style_font_color: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_FONT_COLOR,
        product_bread_crumb_style_text_decoration: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_TEXT_DECORATION,
        product_bread_crumb_style_text_transform: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_TEXT_TRANSFORM,
        product_bread_crumb_style_padding: process.env.REACT_APP_SITE_PRODUCT_BREAD_CRUMB_STYLE_PADDING,
        product_product_title_style_font_size: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_TITLE_STYLE_FONT_SIZE,
        product_product_title_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_TITLE_STYLE_FONT_WEIGHT,
        product_product_title_style_font_color: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_TITLE_STYLE_FONT_COLOR,
        product_product_price_style_font_size: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_PRICE_STYLE_FONT_SIZE,
        product_product_price_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_PRICE_STYLE_FONT_WEIGHT,
        product_product_price_style_font_color: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_PRICE_STYLE_FONT_COLOR,
        product_product_detail_text_style_font_size: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_TEXT_STYLE_FONT_SIZE,
        product_product_detail_text_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_TEXT_STYLE_FONT_WEIGHT,
        product_product_detail_text_style_font_color: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_TEXT_STYLE_FONT_COLOR,
        product_product_detail_paragraph_style_font_size: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_PARAGRAPH_STYLE_FONT_SIZE,
        product_product_detail_paragraph_style_font_weight: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_PARAGRAPH_STYLE_FONT_WEIGHT,
        product_product_detail_paragraph_style_font_color: process.env.REACT_APP_SITE_PRODUCT_PRODUCT_DETAIL_PARAGRAPH_STYLE_FONT_COLOR,

        // pagination
        pageination_font_color: process.env.REACT_APP_SITE_PAGEINATION_FONT_COLOR,
        pageination_font_size: process.env.REACT_APP_SITE_PAGEINATION_FONT_SIZE,
        pageination_border_color: process.env.REACT_APP_SITE_PAGEINATION_BORDER_COLOR,
        pageination_background_color: process.env.REACT_APP_SITE_PAGEINATION_BACKGROUND_COLOR,
        pageination_border_radius: process.env.REACT_APP_SITE_PAGEINATION_BORDER_RADIUS,
        pageination_hover_color: process.env.REACT_APP_SITE_PAGEINATION_HOVER_COLOR,

        pageination_font_selected_color: process.env.REACT_APP_SITE_PAGEINATION_FONT_SELECTED_COLOR,
        pageination_font_hover_color: process.env.REACT_APP_SITE_PAGEINATION_FONT_HOVER_COLOR,

        // related products

        related_products_header_text: process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_TEXT,
        related_products_container_background_color: process.env.REACT_APP_SITE_RELATED_PRODUCTS_CONTAINER_BACKGROUND_COLOR,
        related_products_container_padding: process.env.REACT_APP_SITE_RELATED_PRODUCTS_CONTAINER_PADDING,

        related_products_header_background_color:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_BACKGROUND_COLOR,
        related_products_header_background_image:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_BACKGROUND_IMAGE,
        related_products_header_padding:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_PADDING,
        related_products_header_font_color:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_FONT_COLOR,
        related_products_header_font_size:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_FONT_SIZE,
        related_products_header_font_weight:process.env.REACT_APP_SITE_RELATED_PRODUCTS_HEADER_FONT_WEIGHT,

        related_products_product_title_font_color:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_TITLE_FONT_COLOR,
        related_products_product_title_font_size:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_TITLE_FONT_SIZE,
        related_products_product_title_font_weight:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_TITLE_FONT_WEIGHT,
        related_products_product_price_font_color:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_PRICE_FONT_COLOR,
        related_products_product_price_font_size:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_PRICE_FONT_SIZE,
        related_products_product_price_font_weight:process.env.REACT_APP_SITE_RELATED_PRODUCTS_PRODUCT_PRICE_FONT_WEIGHT,
        // terms

        terms_inner_container_border: process.env.REACT_APP_SITE_TERMS_INNER_CONTAINER_BORDER,
        terms_inner_container_border_radius: process.env.REACT_APP_SITE_TERMS_INNER_CONTAINER_BORDER_RADIUS,
        terms_inner_container_padding: process.env.REACT_APP_SITE_TERMS_INNER_CONTAINER_PADDING,
        terms_inner_container_background_color: process.env.REACT_APP_SITE_TERMS_INNER_CONTAINER_BACKGROUND_COLOR,

        // show page items

        show_order_shipping_pages: process.env.REACT_APP_SITE_SHOW_ORDER_SHIPPING_PAGES,
        show_related_products_pages: process.env.REACT_APP_SITE_SHOW_RELATED_PRODUCTS_PAGES,

    }
};